// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  border-bottom: 1px solid var(--nvz-separator-border);
  padding-right: 4px;
}
:host:last-child {
  border-bottom: none;
}

.disabled .coin {
  cursor: default;
}

.coin-container-chevron {
  position: relative;
}
.coin-container-chevron .menu-chevron {
  font-size: 12px;
  position: absolute;
  right: -4px;
  top: 30px;
  background: var(--nvz-color-accent);
  border-radius: 20px;
  width: 20px;
  height: 20px;
  display: inline-flex;
}

.coin-container {
  touch-action: none;
  -ms-touch-action: none;
  visibility: visible !important;
  transition: all 0.2s ease-out;
}
.coin-container.disabled {
  opacity: 0.3;
}

.mat-icon {
  display: inline;
}

.icon-male:before,
.icon-female:before {
  font-size: 30px;
  line-height: 36px;
}

.mat-mdc-form-field-error {
  visibility: hidden;
}
.mat-mdc-form-field-error.visible {
  visibility: visible;
}

.mat-mdc-form-field {
  padding: 0 10px 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/onboarding/family/person/person.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,oDAAA;EACA,kBAAA;AACF;AAAE;EACE,mBAAA;AAEJ;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,kBAAA;AACF;AACE;EACE,eAAA;EACA,kBAAA;EACA,WAAA;EACA,SAAA;EACA,mCAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;AACJ;;AAGA;EACE,kBAAA;EACA,sBAAA;EACA,8BAAA;EACA,6BAAA;AAAF;AACE;EACE,YAAA;AACJ;;AAGA;EACE,eAAA;AAAF;;AAKE;;EACE,eAAA;EACA,iBAAA;AADJ;;AAKA;EACE,kBAAA;AAFF;AAGE;EACE,mBAAA;AADJ;;AAKA;EACE,oBAAA;AAFF","sourcesContent":[":host {\n  display: block;\n  border-bottom: 1px solid var(--nvz-separator-border);\n  padding-right: 4px;\n  &:last-child {\n    border-bottom: none;\n  }\n}\n\n.disabled .coin {\n  cursor: default;\n}\n\n.coin-container-chevron {\n  position: relative;\n\n  .menu-chevron {\n    font-size: 12px;\n    position: absolute;\n    right: -4px;\n    top: 30px;\n    background: var(--nvz-color-accent);\n    border-radius: 20px;\n    width: 20px;\n    height: 20px;\n    display: inline-flex;\n  }\n}\n\n.coin-container {\n  touch-action: none;\n  -ms-touch-action: none;\n  visibility: visible !important;\n  transition: all 0.2s ease-out;\n  &.disabled {\n    opacity: 0.3;\n  }\n}\n\n.mat-icon {\n  display: inline;\n}\n\n.icon-male,\n.icon-female {\n  &:before {\n    font-size: 30px;\n    line-height: 36px;\n  }\n}\n\n.mat-mdc-form-field-error {\n  visibility: hidden;\n  &.visible {\n    visibility: visible;\n  }\n}\n\n.mat-mdc-form-field {\n  padding: 0 10px 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
