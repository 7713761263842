import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Constants } from 'src/app/app.constants';
import {
  FinancialPortfolio,
  FinancialPortfolioWithContributions,
} from 'src/app/model';

@Component({
  selector: 'app-balance-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.scss'],
})
export class BalanceAssetComponent {
  @Input() entity: FinancialPortfolioWithContributions;
  @Input() allocationPresets: {
    name: string;
    percentages: number[];
  }[] = [];
  @Input() hasEarnedIncomes: boolean;
  @Input() isPrimary = false;
  @Input() riskProfiles: {
    key: string;
    meanReturn: number;
  }[] = [];

  @Output() update = new EventEmitter<FinancialPortfolio>();
  @Output() remove = new EventEmitter<FinancialPortfolio>();
  @Output() updateLinked =
    new EventEmitter<FinancialPortfolioWithContributions>();
  @Output() removeLinked =
    new EventEmitter<FinancialPortfolioWithContributions>();
  @Output() updateContribution = new EventEmitter<{
    entity: FinancialPortfolioWithContributions;
    isEmployer: boolean;
  }>();
  @Output() updateFees =
    new EventEmitter<FinancialPortfolioWithContributions>();

  private _showInputForId: string;

  showNameInput(ref: HTMLInputElement) {
    setTimeout(() => ref.focus(), 50);
  }

  onUpdate() {
    this.update.emit(this.entity.portfolio);
  }

  onUpdateLinked() {
    this.updateLinked.emit(this.entity);
  }

  onUpdateContribution(isEmployer: boolean) {
    this.updateContribution.emit({ entity: this.entity, isEmployer });
  }

  onUpdateFees() {
    this.updateFees.emit(this.entity);
  }

  onRemove() {
    this.remove.emit(this.entity.portfolio);
  }

  onRemoveLinked() {
    this.removeLinked.emit(this.entity);
  }

  isFromLinkedAccounts(data) {
    // return (data.source === Constants.SOURCE_MONEYHUB || data.description === Constants.SOURCE_MONEYHUB);
    return data.source === Constants.SOURCE_MONEYHUB;
  }

  public getFinancialAssetIcon(portfolio: FinancialPortfolio) {
    switch (portfolio.wrapper) {
      case Constants.FINANCIAL_WRAPPER_ID_EMPLOYER_PENSION:
      case Constants.FINANCIAL_WRAPPER_ID_PRIVATE_PENSION:
        return 'icon-savings';
      case Constants.FINANCIAL_WRAPPER_ID_GIA:
      case Constants.FINANCIAL_WRAPPER_ID_ISA:
      default:
        return 'icon-money';
    }
  }

  getPresetLabel(id: string) {
    const entry = Constants.ALLOCATION_PRESET_NAMES.filter(
      (w) => w.id === id
    )[0];
    const meanReturn = this.riskProfiles.find((r) => r.key === id)?.meanReturn;
    if (!entry) {
      return this.formattedPresetLabel(
        id.charAt(0).toUpperCase() + id.slice(1),
        meanReturn
      );
    }
    return this.formattedPresetLabel(entry.label, meanReturn);
  }

  formattedPresetLabel(label: string, meanReturn: number) {
    return `${label} (${meanReturn ?? ''}% expected return)`;
  }

  get showInputForId() {
    return this._showInputForId;
  }

  set showInputForId(id: string) {
    this._showInputForId = id;
  }
}
