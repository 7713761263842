import {
  Component,
  Inject,
  ViewChild,
  ViewContainerRef,
  OnInit,
  ComponentFactoryResolver,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Goal } from 'src/app/model/goal';
import {
  TypedGoalService,
  EditGoalFormFactory,
  UnsecuredLiabilityService,
  PersonsService,
} from 'src/app/services';
import { NotifierService } from 'angular-notifier';
import { Constants } from 'src/app/app.constants';
import { Scenario } from 'src/app/model';
import { ScenarioUtils } from 'src/app/utils';
import { flatMap, map, filter } from 'rxjs/operators';

export interface DialogData {
  goal: Goal;
  scenarioId: string;
  scenario: Scenario;
}

@Component({
  selector: 'app-edit-dialog-component',
  templateUrl: './edit.dialog.component.html',
  styleUrls: ['./edit.dialog.component.scss'],
})
export class EditGoalDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('editRef', { read: ViewContainerRef }) editRef: ViewContainerRef;
  private _goal: Goal;
  private _scenarioId: string;
  private _scenario: Scenario;
  private _showInputForId: string;

  constructor(
    private goalComponentFactory: EditGoalFormFactory,
    private notifier: NotifierService,
    private personService: PersonsService,
    private goalService: TypedGoalService,
    private resolver: ComponentFactoryResolver,
    private dialogRef: MatDialogRef<EditGoalDialogComponent>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) private dialogData: DialogData
  ) {
    this._scenarioId = dialogData.scenarioId;
    this._goal = dialogData.goal;
    this._scenario = dialogData.scenario;
  }

  ngOnInit() {}

  ngAfterViewInit() {
    const cmp = this.goalComponentFactory.getComponent(this.goal);
    const childComponent = this.resolver.resolveComponentFactory(cmp);
    const ref = this.editRef.createComponent(childComponent);

    ref.instance.ctx = {
      goal: this.goal,
      scenarioId: this._scenarioId,
      scenario: this._scenario,
    };
    ref.instance.updateGoal.subscribe(() => this.update());
    ref.instance.deleteGoal.subscribe(() => this.delete());
    this.cdr.detectChanges();
  }

  showNameInput(ref: HTMLInputElement) {
    setTimeout(() => ref.focus(), 50);
  }

  get showInputForId() {
    return this._showInputForId;
  }

  set showInputForId(id: string) {
    this._showInputForId = id;
  }

  get goal() {
    return this._goal;
  }

  set goal(goal: Goal) {
    this._goal = goal;
  }

  close = () => this.dialogRef.close();

  get showDeleteBtn() {
    return ScenarioUtils.getAllGoals(this._scenario).length > 1;
  }

  update() {
    let updateObs$;
    if (
      this._goal.description === JSON.stringify({ type: 'PARTNER_RETIREMENT' })
    ) {
      updateObs$ = this.personService.getPartner(this._scenarioId).pipe(
        flatMap((p) => {
          const expectedRetirementAge =
            new Date(Date.parse(this._goal.startDate)).getFullYear() -
            p.yearOfBirth;
          return this.personService.updatePartner(this._scenarioId, {
            ...p,
            expectedRetirementAge,
          });
        })
      );
    } else {
      updateObs$ = this.goalService.update(this._scenarioId, this._goal);
    }
    updateObs$.subscribe(
      () => this.notifier.notify(Constants.SUCCESS, 'Goal updated'),
      (err) => this.notifier.notify(Constants.ERROR, err),
      () => this.dialogRef.close(true)
    );
  }

  delete() {
    this.goalService.delete(this._scenarioId, this._goal.id).subscribe(
      () => this.notifier.notify(Constants.SUCCESS, 'Goal deleted'),
      (err) => this.notifier.notify(Constants.ERROR, err),
      () => this.dialogRef.close(true)
    );
  }
}
