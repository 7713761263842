import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Constants } from 'src/app/app.constants';
import { Expense } from 'src/app/model/household/expense/expense';

interface IViewExpense extends Expense {
  id: string;
  name: string;
  isFromLinkedAccounts: boolean;
  nonDiscretionaryPercentage: number;
}

@Component({
  selector: 'app-expense',
  templateUrl: './expense.component.html',
  styleUrls: ['./expense.component.scss'],
})
export class ExpenseComponent {
  @Input() expense: IViewExpense;

  @Output() update = new EventEmitter<Expense>();
  @Output() remove = new EventEmitter<Expense>();
  @Output() updateLinked = new EventEmitter<Expense>();
  @Output() removeLinked = new EventEmitter<Expense>();

  private _showInputForId: string;

  onUpdate() {
    this.update.emit(this.expense);
  }

  onRemove() {
    this.remove.emit(this.expense);
  }

  showNameInput(ref: HTMLInputElement) {
    setTimeout(() => ref.focus(), 50);
  }

  getFrequencyLabel(frequency) {
    return Constants.FREQUENCY_LABELS.find((f) => f.id === frequency).label;
  }

  get showInputForId() {
    return this._showInputForId;
  }

  set showInputForId(id: string) {
    this._showInputForId = id;
  }

  get frequencies() {
    return Constants.SELECTABLE_FREQUENCIES;
  }
}
