import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  signal,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Constants } from 'src/app/app.constants';
import { Gender, Person } from 'src/app/model';

@Component({
  selector: 'app-family-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss'],
})
export class FamilyPersonComponent implements OnInit {
  @Input() person: Person;
  @Input() form: FormGroup;
  @Input() isDependant = false;

  @Output() update = new EventEmitter();
  @Output() updateGender = new EventEmitter<string>();
  @Output() remove = new EventEmitter();

  private _healthStatuses = Constants.HEALTH_STATUSES;
  private _jobTypes = Constants.JOB_TYPES;

  isRemoveAvailable = false;
  isEdit = signal(true);

  ngOnInit(): void {
    this.isRemoveAvailable = this.remove.observed;
  }

  getGenderFriendlyName(gender: Gender) {
    switch (gender) {
      case Gender.MALE:
      case Gender.TRANS_MALE:
      case Gender.OTHER_MALE:
        return this.isDependant ? 'Boy' : 'Man';
      case Gender.FEMALE:
      case Gender.OTHER_FEMALE:
      case Gender.TRANS_FEMALE:
        return this.isDependant ? 'Girl' : 'Woman';
      case Gender.OTHER:
        return 'Other';
    }
  }

  get ageList() {
    return this.isDependant ? this.ageListMinor : this.ageListAdult;
  }

  get age() {
    return new Date().getFullYear() - this.person.yearOfBirth;
  }

  get jobType() {
    return this._jobTypes.find((t) => t.key === this.person.jobType).label;
  }

  get healthStatus() {
    return this._healthStatuses.find((h) => h.key === this.person.healthStatus)
      .label;
  }

  private get ageListAdult() {
    return Array.from(Array(100 - 18).keys()).map((i) => i + 18);
  }

  private get ageListMinor() {
    return Array.from(Array(100).keys());
  }

  get healthStatuses(): { key: string; label: string }[] {
    return this._healthStatuses;
  }

  get jobTypes(): { key: string; label: string }[] {
    return this._jobTypes;
  }

  onUpdate() {
    this.update.emit();
  }

  onUpdateGender(gender) {
    this.updateGender.emit(gender);
  }

  onRemove() {
    this.remove.emit();
  }
}
