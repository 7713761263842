// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  border-bottom: 1px solid var(--nvz-separator-border);
}
:host:last-child {
  border-bottom: none;
}

.mat-mdc-form-field {
  padding: 0 10px 10px;
}

.mat-mdc-form-field-error {
  display: none;
}
.mat-mdc-form-field-error.visible {
  display: block;
}

.button-cancel {
  display: flex;
  position: relative;
  top: 10px;
  align-self: flex-start;
}

.mat-icon {
  display: inline;
}`, "",{"version":3,"sources":["webpack://./src/app/components/onboarding/income-statement/expense/expense.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,oDAAA;AACF;AAAE;EACE,mBAAA;AAEJ;;AACA;EACE,oBAAA;AAEF;;AACA;EACE,aAAA;AAEF;AADE;EACE,cAAA;AAGJ;;AACA;EACE,aAAA;EACA,kBAAA;EACA,SAAA;EACA,sBAAA;AAEF;;AACA;EACE,eAAA;AAEF","sourcesContent":[":host {\n  display: block;\n  border-bottom: 1px solid var(--nvz-separator-border);\n  &:last-child {\n    border-bottom: none;\n  }\n}\n.mat-mdc-form-field {\n  padding: 0 10px 10px;\n}\n\n.mat-mdc-form-field-error {\n  display: none;\n  &.visible {\n    display: block;\n  }\n}\n\n.button-cancel {\n  display: flex;\n  position: relative;\n  top: 10px;\n  align-self: flex-start;\n}\n\n.mat-icon {\n  display: inline;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
