import {
  AfterViewInit,
  Component,
  ElementRef,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSliderChange } from '@angular/material/slider';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment/moment';
import { pathOr } from 'ramda';
import { Observable, concat, from, iif, of } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  finalize,
  first,
  map,
  mergeMap,
  takeLast,
  tap,
} from 'rxjs/operators';
import { Constants } from 'src/app/app.constants';
import {
  ActionType,
  Actions,
  FinancialWrapper,
  InvestmentProperty,
  Liability,
  LiabilityTypes,
  MortgageLiability,
  PercentagedAction,
  Person,
  RentAction,
  Scenario,
  UnsecuredLiability,
} from 'src/app/model';
import { Band } from 'src/app/model/household/result/band';
import { GoalAchievability } from 'src/app/model/household/result/goal.achievability';
import { ScenarioData } from 'src/app/model/household/result/scenario.data';
import { ResultsBandedGoalAchievabilityService } from 'src/app/services/results.banded.goal.achievability.service';
import { ResultsBandedGoalDiagnosticService } from 'src/app/services/results.banded.goal.diagnostic.service';
import { ResultsScenarioBandService } from 'src/app/services/results.scenario.band.service';
import { ResultsScenarioDataService } from 'src/app/services/results.scenario.data.service';
import { ResultsScenarioGoalService } from 'src/app/services/results.scenario.goal.service';
import { ScenarioService } from 'src/app/services/scenario.service';
import { GoalUtils } from 'src/app/utils/goal.utils';
import { DiagnosticDialogComponent } from '../diagnostics/diagnostic.dialog.component';

import { DomSanitizer, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { GoogleChartInterface } from 'ng2-google-charts';
import { LinkToBubeleDialogComponent } from 'src/app/components/common/link-to-bubele-dialog/link-to-bubele.dialog.component';
import { UpgradeDialogComponent } from 'src/app/components/common/upgrade-dialog/upgrade.dialog.component';
import { AppFeatureType } from 'src/app/feature';
import { FeatureService } from 'src/app/feature/feature.service';
import { AnalyticsEvent, AnalyticsProperty } from 'src/app/model/analytics';
import { BaseGrowthRate, DateRangeType, Frequency } from 'src/app/model/enums';
import { Asset } from 'src/app/model/financial/asset';
import { Goal } from 'src/app/model/goal';
import { LivingExpense } from 'src/app/model/household/expense';
import { Expense } from 'src/app/model/household/expense/expense';
import { RentExpense } from 'src/app/model/household/expense/rent.expense';
import {
  EarnedIncome,
  EarnedIncomeBuilder,
  RentIncome,
  UnearnedIncome,
} from 'src/app/model/household/income';
import { Income } from 'src/app/model/household/income/income';
import { Insurance } from 'src/app/model/protection';
import {
  AnalyticsService,
  EarnedIncomeService,
  ExpenseService,
  FinancialAssetsService,
  IncomeService,
  InsuranceService,
  LiabilityService,
  LivingExpenseService,
  MortgageLiabilityService,
  PersonsService,
  PropertyService,
  RentExpenseService,
  RentIncomeService,
  ResultsOverallGoalAchievabilityService,
  TypedGoalService,
  UnearnedIncomeService,
  UnsecuredLiabilityService,
} from 'src/app/services';
import { AppConfigService } from 'src/app/services/app.config.service';
import { AssetService } from 'src/app/services/asset.service';
import { DiagnosticsContextFactory } from 'src/app/services/diagnostics.context.factory';
import { PreferenceService } from 'src/app/services/preference.service';
import { SolverService } from 'src/app/services/solver.service';
import { DateUtils, GuestUtils, ScenarioUtils } from 'src/app/utils';
import { DeleteScenarioDialogComponent } from '../delete-scenario/delete.scenario.dialog.component';
import { EditGoalDialogComponent } from '../edit/edit.dialog.component';
import { SolverDialogComponent } from './solver-dialog/solver-dialog.component';

@Component({
  selector: 'graph-component',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss'],
})
export class GraphComponent implements AfterViewInit {
  @ViewChild('baseGraphRef') baseGraphRef: ElementRef;
  @ViewChild('otherGraphRef') otherGraphRef: ElementRef;
  @ViewChildren('goalContainer') goalContainerRef: QueryList<ElementRef>;
  @ViewChildren('alternateGoalContainer')
  alternateGoalContainerRef: QueryList<ElementRef>;

  @ViewChild('newScenarioNameInput', { read: ElementRef<HTMLInputElement> })
  scenarioNameInputRef: ElementRef<HTMLInputElement>;

  private timer: any;

  public verticalLines: any[] = [];
  public horizontalLines: any[] = [];
  private _graphYearList: any[] = [];
  private _locale: string;
  private _scenarioId: string;
  private _alternateScenarioId: string;
  private _primary: Person;
  private _partner: Person;
  private _children: Person[];

  private _incomes: Income[] = [];
  private _rentIncomes: RentIncome[] = [];

  private _expenses: Expense[] = [];
  private _assets: Asset[] = [];
  private _liabilities: Liability[] = [];
  private _insurances: Insurance[] = [];
  private _assetWrappers: FinancialWrapper[] = [];
  private _assetAllocationPresets: [][] = [];

  private _scenarios: Scenario[] = [];
  private _scenarioData: { [scenarioId: string]: ScenarioData } = {};
  private _goalData: { [scenarioId: string]: Array<any> } = {};
  private _graphData: any;
  private _bandId: string = Constants.DEFAULT_ACHIEVABILITY_BAND_ID_LIKELY;
  private _bands: { [scenarioId: string]: Array<Band> } = {};
  private _loading = false;
  private _goalPopups: { [container: string]: { [id: string]: boolean } };
  private _duration: string = Constants.MAX_DURATION;

  public gradient = this.sanitizer.bypassSecurityTrustStyle('white');
  public drawerVisible: boolean;

  public baseScenarioId: string;
  public otherScenarioId: string;
  private _scenarioDiff: any;
  public diffSliderValue: number;
  private _typedGoals: any[];
  private _actions: Actions;

  private _dummyPartnerRetGoalBand: GoalAchievability = {
    bandId: '2',
    goalId: '',
    id: '',
    name: null,
    description: null,
    source: '',
    totalAchieved: 0,
    totalAlive: 0,
    totalLives: 0,
    totalNotAchieved: 0,
  };
  private _dummyPartnerRetGoalOverall: GoalAchievability = {
    goalId: '',
    id: '',
    name: null,
    description: null,
    source: '',
    totalAchieved: 0,
    totalAlive: 500,
    totalLives: 500,
    totalNotAchieved: 500,
  };

  private _skipAchievability = false;

  constructor(
    private configService: AppConfigService,
    private featureService: FeatureService,
    private titleService: Title,
    private t: TranslateService,
    private analyticsService: AnalyticsService,
    private notifierService: NotifierService,
    private typedGoalService: TypedGoalService,
    private rentIncomeService: RentIncomeService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private preferenceService: PreferenceService,
    private personsService: PersonsService,
    private incomeService: IncomeService,
    private expenseService: ExpenseService,
    private assetService: AssetService,
    private liabilityService: LiabilityService,
    private insuranceService: InsuranceService,
    private financialAssetsService: FinancialAssetsService,
    private scenarioService: ScenarioService,
    private resultsScenarioGoalService: ResultsScenarioGoalService,
    private bandedGoalAchievabilityService: ResultsBandedGoalAchievabilityService,
    private overallGoalAchievabilityService: ResultsOverallGoalAchievabilityService,
    private bandedGoalDiagnosticService: ResultsBandedGoalDiagnosticService,
    private resultsScenarioBandService: ResultsScenarioBandService,
    private resultsScenarioDataService: ResultsScenarioDataService,
    private diagnosticsContextFactory: DiagnosticsContextFactory,
    private solverService: SolverService,
    private earnedIncomeService: EarnedIncomeService,
    private unearnedIncomeService: UnearnedIncomeService,
    private propertyService: PropertyService,
    private livingExpenseService: LivingExpenseService,
    private rentExpenseService: RentExpenseService,
    private unsecuredLiabilityService: UnsecuredLiabilityService,
    private mortgageLiabilityService: MortgageLiabilityService
  ) {
    this.diffSliderValue = 1;
    this._goalPopups = { main: {}, alternate: {} };
  }

  ngOnInit() {
    this._locale = this.configService.getConfig().locale;
    this.drawerVisible = false;
    this.titleService.setTitle(this.t.instant('Envizage | Overview'));
    this._loading = true;
    this.refreshResults$()
      .pipe(
        catchError((err) => {
          this._loading = false;
          return of({});
        })
      )
      .subscribe(() => {
        this._loading = false;

        if (this.featureService.hasFeature(AppFeatureType.LinkToBubelePopup)) {
          this.timer = setTimeout(
            () => this.showLinkToBubelePopup(),
            Constants.BUBELE_POPUP_WAIT_TIME
          );
        }

        this.buildGraph(this._scenarioId, false);
        if (this._scenarios.length > 1) {
          this._alternateScenarioId = this._scenarios.filter(
            (s) => s.id !== this._scenarioId
          )[0].id;
          this.buildGraph(this._alternateScenarioId, true);
        }
        this.buildGradientOverlay(
          this._scenarioData[
            this._scenarios.find((s) => s.id === this._scenarioId).id
          ]
        );
        if (this.currentScenario.name !== '') {
          this.onSetAsCurrentScenarioTemp();
        }
      });
  }

  ngAfterViewInit() {
    this.otherGraphRef.nativeElement.style.opacity = this.diffSliderValue / 100;
    this.baseGraphRef.nativeElement.style.opacity =
      1 - this.diffSliderValue / 100;
    this.baseGraphRef.nativeElement.style.zIndex = 50;
    this.otherGraphRef.nativeElement.style.zIndex = 1;
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  toggle(goalData, container, event) {
    this._goalPopups[container][goalData.data.id] =
      !this._goalPopups[container][goalData.data.id];
    event.stopPropagation();
  }

  hideGoalPopup(goalData, container) {
    delete this._goalPopups[container][goalData.data.id];
  }

  popupOpen(goalData, container) {
    return !!this._goalPopups[container][goalData.data.id];
  }

  refreshResults$ = (): Observable<any> => {
    let current: Scenario;
    return this.route.params
      .pipe(first())
      .pipe(
        mergeMap((p) => {
          this._scenarioId = p['scenarioId'];
          return this.scenarioService.query(Constants.PAGE_ALL);
        })
      )
      .pipe(
        mergeMap((resp) => {
          this._scenarios = resp.content;
          current = resp.content.find((s) => s.id === this._scenarioId);
          return concat(
            ...resp.content.map((s) =>
              this.preferenceService.update(
                s.id,
                Constants.PREFERENCE_KEY_INITIALIZED,
                'true'
              )
            )
          ).pipe(takeLast(1));
        })
      )
      .pipe(mergeMap(() => this.collectData$()))
      .pipe(
        concatMap(() =>
          concat(
            ...this._scenarios.map((s) =>
              this.executeSimulation$(s).pipe(
                mergeMap(() => this.queryResults$(s.id))
              )
            )
          ).pipe(takeLast(1))
        )
      )
      .pipe(
        mergeMap(() =>
          this.scenarioService.update({
            id: current.id,
            name: current.name,
          })
        )
      )
      .pipe(
        mergeMap(() =>
          this.preferenceService.update(
            current.id,
            Constants.PREFERENCE_KEY_CURRENT,
            true
          )
        )
      );
  };

  collectData$ = (): Observable<any> => {
    return of({})
      .pipe(
        mergeMap(() =>
          this.personsService
            .getPrimary(this._scenarioId)
            .pipe(map((r) => (this._primary = r)))
        )
      )
      .pipe(
        mergeMap(() =>
          this.personsService
            .getPartner(this._scenarioId)
            .pipe(map((r) => (this._partner = r)))
        )
      )
      .pipe(
        mergeMap(() =>
          this.typedGoalService
            .query(this._scenarioId, Constants.PAGE_ALL)
            .pipe(map((r) => (this._typedGoals = r.content)))
        )
      )
      .pipe(
        mergeMap(() =>
          this.personsService
            .queryChildren(this._scenarioId, Constants.PAGE_ALL)
            .pipe(map((r) => (this._children = r.content)))
        )
      )
      .pipe(
        mergeMap(() =>
          this.incomeService
            .query(this._scenarioId, Constants.PAGE_ALL)
            .pipe(map((r) => (this._incomes = r.content)))
        )
      )
      .pipe(
        mergeMap(() =>
          this.expenseService
            .query(this._scenarioId, Constants.PAGE_ALL)
            .pipe(map((r) => (this._expenses = r.content)))
        )
      )
      .pipe(
        mergeMap(() =>
          this.assetService
            .query(this._scenarioId, Constants.PAGE_ALL)
            .pipe(map((r) => (this._assets = r.content)))
        )
      )
      .pipe(
        mergeMap(() =>
          this.rentIncomeService
            .queryForPerson(
              this._scenarioId,
              this._primary.id,
              Constants.PAGE_ALL
            )
            .pipe(map((r) => (this._rentIncomes = r.content)))
        )
      )
      .pipe(
        mergeMap(() =>
          this.liabilityService
            .query(this._scenarioId, Constants.PAGE_ALL)
            .pipe(map((r) => (this._liabilities = r.content)))
        )
      )
      .pipe(
        mergeMap(() =>
          this.insuranceService
            .query(this._scenarioId, Constants.PAGE_ALL)
            .pipe(map((r) => (this._insurances = r.content)))
        )
      )
      .pipe(
        mergeMap(() =>
          this.financialAssetsService
            .queryFinancialWrappers(Constants.PAGE_ALL)
            .pipe(map((r) => (this._assetWrappers = r.content)))
        )
      )

      .pipe(
        mergeMap(() =>
          this.scenarioService.query(Constants.PAGE_ALL).pipe(
            map((r) => {
              const scenario = this._scenarios.find(
                (s) => s.household.preferences[Constants.PREFERENCE_KEY_CURRENT]
              );
              this._scenarios = r.content;
              this.baseScenarioId = scenario.id;
              this.otherScenarioId = scenario.id;
              this.otherScenarioId = pathOr(
                scenario.id,
                ['id'],
                this._scenarios.find((s) => s.id !== scenario.id)
              );
              this._assetAllocationPresets =
                scenario.household.preferences[
                  Constants.ASSET_ALLOCATION_PRESETS_PREFERENCE_NAME
                ];

              this.analyticsService.setAnalyticsUserProperty(
                AnalyticsProperty.CURRENT_SCENARIO,
                scenario.name
              );
            })
          )
        )
      )
      .pipe(
        mergeMap(() =>
          this.scenarioService
            .diff(this.baseScenarioId, this.otherScenarioId)
            .pipe(map((r) => (this._scenarioDiff = r)))
        )
      );
  };

  cloneCurrentScenario$(name: string) {
    let scenario = null;
    return of({}).pipe(
      map(() =>
        this.analyticsService.trackAnalyticsEvent(
          AnalyticsEvent.CREATE_SCENARIO,
          name
        )
      ),
      concatMap(() =>
        this.scenarioService.clone({ id: this._scenarioId, name: name })
      ),
      tap((s) => (scenario = s)),
      mergeMap((s) =>
        this.preferenceService.update(
          s.id,
          Constants.PREFERENCE_KEY_INITIALIZED,
          'true'
        )
      ),
      mergeMap(() => this.scenarioService.query(Constants.PAGE_ALL)),
      mergeMap((resp) => {
        this._scenarios = resp.content;
        this.onSetAsCurrentScenarioTemp();
        return this.executeSimulation$(scenario);
      }),
      mergeMap(() => this.queryResults$(scenario.id)),
      map(() => scenario)
    );
  }

  cloneCurrentScenario(name: string) {
    this._loading = true;
    this.scenarioNameInputRef.nativeElement.value = '';
    this.cloneCurrentScenario$(name)
      .pipe(
        concatMap((s) => {
          this.otherScenarioId = this._alternateScenarioId = s.id;
          return this.setScenarioCurrent$(s);
        }),
        mergeMap(() =>
          this.scenarioService.diff(this.baseScenarioId, this.otherScenarioId)
        )
      )
      .subscribe(
        (diff) => {
          this._loading = false;
          this._scenarioDiff = diff;
          this.buildGraph(this._scenarioId, false);
          this.buildGraph(this.otherScenarioId, true);
          this.buildGradientOverlay(this._scenarioData[this._scenarioId]);
        },
        () => {
          this._loading = false;
          this.notifierService.notify(
            Constants.ERROR,
            'Failed to clone scenario'
          );
        },
        () => this.notifierService.notify(Constants.SUCCESS, 'Scenario cloned')
      );
  }

  showDeleteDialog(id: string) {
    let toDelete: boolean;
    let comparedScenarioId: string;
    this.dialog
      .open(DeleteScenarioDialogComponent, {
        width: '400px',
        height: '150px',
        data: {
          scenario: this._scenarios.find((s) => s.id === id),
          householdId: this._scenarioId,
        },
      })
      .afterClosed()
      .pipe(
        tap((deleteScenario) => {
          if (deleteScenario) {
            this._loading = true;
            toDelete = deleteScenario;
            comparedScenarioId = this.otherScenarioId;
          }
        }),
        filter(() => toDelete),
        concatMap(() => this.scenarioService.delete(id)),
        concatMap(() => {
          this.analyticsService.trackAnalyticsEvent(
            AnalyticsEvent.DELETE_SCENARIO,
            this._scenarios.find((s) => s.id === id).name
          );
          delete this._goalData[id];
          if (comparedScenarioId === id) {
            if (this.drawerVisible) {
              this.toggleDrawer();
            }
            this.diffSliderValue = 0;
            this.otherGraphRef.nativeElement.style.opacity = 0;
            this.baseGraphRef.nativeElement.style.opacity = 1;
            return this.refreshResults$();
          } else {
            return this.scenarioService.query(Constants.PAGE_ALL);
          }
        }),
        map((res) => {
          if (
            typeof res === 'object' &&
            (res !== null || res !== undefined) &&
            Array.isArray(res.content)
          ) {
            this._scenarios = res.content;
          }
          if (comparedScenarioId === id) {
            if (this._scenarios.length > 1) {
              this._alternateScenarioId = this._scenarios.filter(
                (s) => s.id !== this._scenarioId
              )[0].id;
            }
            this.positionGoals();
          }
        })
      )
      .subscribe({
        next: () => {
          if (toDelete) {
            this.onSetAsCurrentScenarioTemp();
          }
          this._loading = false;
        },
        error: () => {
          this.notifierService.notify(
            Constants.ERROR,
            'Failed to delete scenario'
          );
        },
        complete: () => {
          if (toDelete) {
            this.notifierService.notify(Constants.SUCCESS, 'Scenario deleted');
          }
        },
      });
  }

  setScenarioCurrent$(scenario: Scenario) {
    if (scenario.household.preferences[Constants.PREFERENCE_KEY_CURRENT]) {
      return of({});
    }
    this._alternateScenarioId = this._scenarioId;
    return this.scenarioService
      .update({ name: scenario?.name, id: scenario?.id })
      .pipe(
        mergeMap((s) =>
          from(this.router.navigate(['scenario', s.id, 'overview', 'graph']))
        ),
        mergeMap(() =>
          this.preferenceService.update(
            scenario.id,
            Constants.PREFERENCE_KEY_CURRENT,
            true
          )
        ),
        map(() =>
          this.analyticsService.trackAnalyticsEvent(
            AnalyticsEvent.CHANGE_SCENARIO,
            scenario.name
          )
        ),
        mergeMap(() => this.collectData$()),
        tap(() => {
          this._scenarioId = scenario.id;
          this.baseScenarioId = this._scenarioId;
          this.otherScenarioId = this._alternateScenarioId;
          this.onSetAsCurrentScenarioTemp();
        })
      );
  }

  setAsCurrent(scenario: Scenario) {
    this._loading = true;
    if (scenario.household.preferences[Constants.PREFERENCE_KEY_CURRENT]) {
      this._loading = false;
      return;
    }
    this.diffSliderValue = 1;
    this.baseGraphRef.nativeElement.style.opacity =
      1 - this.diffSliderValue / 100;
    this.otherGraphRef.nativeElement.style.opacity = this.diffSliderValue / 100;
    this.baseGraphRef.nativeElement.style.zIndex = 50;
    this.otherGraphRef.nativeElement.style.zIndex = 1;

    this.setScenarioCurrent$(scenario)
      .pipe(
        mergeMap(() =>
          this.scenarioService.diff(this.baseScenarioId, this.otherScenarioId)
        )
      )
      .subscribe((diff) => {
        this._scenarioDiff = diff;
        this._loading = false;
        this.buildGraph(this._scenarioId);
        this.buildGraph(this._alternateScenarioId, true);
        this.buildGradientOverlay(this._scenarioData[this._scenarioId]);
        this.positionGoals();
      }),
      () => {
        this._loading = false;
        this.notifierService.notify(
          Constants.ERROR,
          'Failed to activate scenario'
        );
      },
      () =>
        this.notifierService.notify(Constants.SUCCESS, 'Scenario activated');
  }

  // After all scenarios are executed, the calling function needs to make sure that the initial scenario is set to current again
  private executeSimulation$ = (scenario: Scenario): Observable<any> => {
    return of({})
      .pipe(
        map(() =>
          this.analyticsService.trackAnalyticsEvent(
            AnalyticsEvent.START_CALCULATION,
            ''
          )
        )
      )
      .pipe(() =>
        this.scenarioService.update({
          id: scenario.id,
          name: scenario.name,
        })
      )
      .pipe(
        mergeMap(() =>
          this.preferenceService.update(
            scenario.id,
            Constants.PREFERENCE_KEY_CURRENT,
            true
          )
        )
      )
      .pipe(
        mergeMap(() =>
          this.scenarioService.replace({
            id: scenario ? scenario.id : this._scenarioId,
            name: scenario
              ? scenario.name
              : this._scenarios.find((s) => s.id === this._scenarioId).name,
          })
        )
      )

      .pipe(
        mergeMap(() =>
          this.scenarioService.execute(
            scenario ? scenario.id : this._scenarioId
          )
        )
      );
  };

  private queryResults$ = (scenarioId: string): Observable<any> => {
    this._skipAchievability = false;
    const sId = scenarioId || this._scenarioId;
    return of({})
      .pipe(
        mergeMap(() =>
          this.resultsScenarioDataService
            .get(sId)
            .pipe(map((d) => (this._scenarioData[sId] = d)))
        )
      )

      .pipe(
        mergeMap(() =>
          this.resultsScenarioBandService
            .query(sId, Constants.PAGE_ALL)
            .pipe(map((d) => (this._bands[sId] = d.content)))
        )
      )

      .pipe(
        mergeMap(() =>
          this.resultsScenarioGoalService
            .query(sId, Constants.PAGE_ALL)
            .pipe(
              map((r) => {
                this._goalData[sId] = [];
                return r;
              })
            )
            .pipe(
              map((r) =>
                r.content.forEach((g) =>
                  this._goalData[sId].push({
                    data: g,
                    banded: null,
                    overall: null,
                    style: {},
                  })
                )
              )
            )
        )
      )

      .pipe(
        map(() => {
          const primaryRetirement = this._goalData[sId].filter(
            (g) => g.data.type === 'RetirementGoal'
          )[0];
          const primaryRetYear = primaryRetirement.data.endDate;
          if (
            this._goalData[sId].length === 1 &&
            moment(primaryRetYear).year() < new Date().getFullYear()
          ) {
            this._skipAchievability = true;
          }
          if (this._partner) {
            const partnerGoalData = JSON.parse(
              JSON.stringify(primaryRetirement)
            );
            partnerGoalData.data.id = primaryRetirement.data.id + '_PARTNER';
            partnerGoalData.data.description = JSON.stringify({
              type: 'PARTNER_RETIREMENT',
            });
            partnerGoalData.data.name = "Partner's retirement";
            partnerGoalData.data.startDate = DateUtils.atYear(
              this._partner.yearOfBirth + this._partner.expectedRetirementAge
            );
            partnerGoalData.data.endDate = DateUtils.atYear(
              this._partner.yearOfBirth + this._partner.expectedRetirementAge
            );
            this._goalData[sId].push(partnerGoalData);
            if (
              this._goalData[sId].length >= 2 &&
              moment(primaryRetYear).year() < new Date().getFullYear() &&
              moment(partnerGoalData.data.endDate).year() >=
                new Date().getFullYear()
            ) {
              const partnerRetGoalIndex = this._goalData[sId].findIndex(
                (g) => g.data.name === "Partner's retirement"
              );
              if (partnerRetGoalIndex !== -1) {
                this._goalData[sId][partnerRetGoalIndex] = {
                  ...this._goalData[sId][partnerRetGoalIndex],
                  banded: this._dummyPartnerRetGoalBand,
                  overall: this._dummyPartnerRetGoalOverall,
                };
              }
              if (this._goalData[sId].length === 2) {
                this._skipAchievability = true;
              }
            }
          }
        })
      )
      .pipe(
        mergeMap(() => {
          if (this._skipAchievability) {
            return of({});
          }
          return this.bandedGoalAchievabilityService
            .queryByBandId(sId, this._bandId, Constants.PAGE_ALL)
            .pipe(
              map((r) =>
                r.content.forEach((a) => {
                  this._goalData[sId].find(
                    (d) => d.data.id === a.goalId
                  ).banded = a;
                  const goal = this._goalData[sId].find(
                    (d) => d.data.id === a.goalId
                  );
                  if (this._partner && goal.data.type === 'RetirementGoal') {
                    const partnerRetirementGoalData = this._goalData[sId].find(
                      (g) =>
                        g.data.description &&
                        JSON.parse(g.data.description).type ===
                          'PARTNER_RETIREMENT'
                    );
                    if (!partnerRetirementGoalData) {
                      return;
                    }
                    partnerRetirementGoalData.banded = a;
                  }
                })
              )
            );
        })
      )

      .pipe(
        mergeMap(() => {
          if (this._skipAchievability) {
            return of({});
          }
          return this.overallGoalAchievabilityService
            .query(sId, Constants.PAGE_ALL)
            .pipe(
              map((r) =>
                r.content.forEach((a) => {
                  this._goalData[sId].find(
                    (d) => d.data.id === a.goalId
                  ).overall = a;
                  const goal = this._goalData[sId].find(
                    (d) => d.data.id === a.goalId
                  );
                  if (this._partner && goal.data.type === 'RetirementGoal') {
                    const partnerRetirementGoalData = this._goalData[sId].find(
                      (g) =>
                        g.data.description &&
                        JSON.parse(g.data.description).type ===
                          'PARTNER_RETIREMENT'
                    );
                    if (!partnerRetirementGoalData) {
                      return;
                    }
                    partnerRetirementGoalData.overall = a;
                  }
                })
              )
            );
        })
      );
  };

  public networthChart: GoogleChartInterface = {
    chartType: 'AreaChart',
    dataTable: {
      cols: [
        { id: 'year', label: 'Year', type: 'number' },
        { id: 'median', label: 'Net worth', type: 'number' },
        { id: 'longevity', label: 'Longevity', type: 'number' },
        {
          id: 'runningOutOfMoney',
          label: 'Running out of money',
          type: 'number',
        },
      ],
      rows: [],
    },
    options: {
      height: window.innerWidth < 599 ? 200 : 380,
      chartArea: { left: 50, width: '100%', height: '90%' },
      lineWidth: 1,
      fontSize: 12,
      fontName: 'Poppins',
      curveType: 'function',
      focusTarget: 'category',
      animation: {
        duration: 1000,
        easing: 'out',
      },
      tooltip: {
        isHtml: true,
      },
      series: {
        1: { lineWidth: 0, visibleInLegend: false },
        2: { lineWidth: 0, visibleInLegend: false },
      },
      colors: ['#00d68a'],
      vAxis: {
        format: 'short',
        gridlines: {
          count: 8,
          color: '#fafafa',
        },
        viewWindowMode: 'maximized',
      },
      hAxis: {
        format: '#',
        gridlines: {
          count: 12,
          color: '#fafafa',
        },
      },
      legend: 'none',
    },
  };

  public networthChartAlternate: GoogleChartInterface = {
    chartType: 'AreaChart',
    dataTable: {
      cols: [
        { id: 'year', label: 'Year', type: 'number' },
        { id: 'median', label: 'Net worth', type: 'number' },
        { id: 'longevity', label: 'Longevity', type: 'number' },
        {
          id: 'runningOutOfMoney',
          label: 'Running out of money',
          type: 'number',
        },
      ],
      rows: [],
    },
    options: {
      height: window.innerWidth < 599 ? 200 : 380,
      chartArea: { left: 50, width: '100%', height: '90%' },
      lineWidth: 1,
      fontSize: 12,
      fontName: 'Poppins',
      curveType: 'function',
      focusTarget: 'category',
      animation: {
        duration: 1000,
        easing: 'out',
      },
      tooltip: {
        isHtml: true,
      },
      series: {
        1: { lineWidth: 0, visibleInLegend: false },
        2: { lineWidth: 0, visibleInLegend: false },
      },
      vAxis: {
        format: 'short',
        gridlines: {
          count: 8,
          color: '#fafafa',
        },
        viewWindowMode: 'maximized',
      },
      hAxis: {
        format: '#',
        gridlines: {
          count: 12,
          color: '#fafafa',
        },
      },
      legend: 'none',
    },
  };

  onSliderChange(event: MatSliderChange) {
    this.baseGraphRef.nativeElement.style.opacity = 1 - event.value / 100;
    this.otherGraphRef.nativeElement.style.opacity = event.value / 100;
    this.goalContainerRef.forEach(
      (g) => (g.nativeElement.style.opacity = 1 - event.value / 100)
    );
    this.alternateGoalContainerRef.forEach(
      (g) => (g.nativeElement.style.opacity = event.value / 100)
    );
    this.positionGoals();
    // if (event.value >= 50) {
    //   this.baseGraphRef.nativeElement.style.zIndex = 1;
    //   this.otherGraphRef.nativeElement.style.zIndex = 50;
    //   this.goalContainerRef.forEach((g) => (g.nativeElement.style.zIndex = 1));
    //   this.alternateGoalContainerRef.forEach(
    //     (g) => (g.nativeElement.style.zIndex = 6)
    //   );
    // } else {
    //   this.baseGraphRef.nativeElement.style.zIndex = 5;
    //   this.otherGraphRef.nativeElement.style.zIndex = 1;
    //   this.goalContainerRef.forEach((g) => (g.nativeElement.style.zIndex = 6));
    //   this.alternateGoalContainerRef.forEach(
    //     (g) => (g.nativeElement.style.zIndex = 1)
    //   );
    // }
  }

  private buildGraph = (scenarioId: string, alternateChart = false) => {
    try {
      const data: ScenarioData = this._scenarioData[scenarioId];

      if (alternateChart) {
        this.networthChartAlternate.dataTable.rows = [];
      } else {
        this.networthChart.dataTable.rows = [];
      }

      if (!data) {
        return;
      }

      let startYear = Number(moment(data.startDate).format('YYYY'));
      const percentile =
        this._bands[scenarioId][parseInt(this._bandId) - 1].upperBound;

      let maxYear = this.getMaxSimulationDataYear(data) - startYear;
      if (this._duration === '5') {
        maxYear++;
      }
      for (let i = 0; i < maxYear; i++, startYear++) {
        const netWorth = !data.percentileData[i][percentile]
          ? 0
          : (
              data.percentileData[i][percentile][Constants.NET_WORTH_TOTAL] || 0
            ).toFixed(2);
        const longevity = !data.data[i]
          ? 0
          : data.data[i][Constants.TOTAL_ALIVE] /
            data.data[i][Constants.TOTAL_LIVES];
        const runningOutOfMoney = !data.data[i]
          ? 0
          : 1 - data.data[i][Constants.WILL_I_HAVE_MONEY];

        if (alternateChart) {
          this.networthChartAlternate.dataTable.rows.push({
            c: [
              { v: startYear, f: startYear },
              { v: netWorth, f: `£ ${Math.round(netWorth / 1000) * 1000}` },
              { v: longevity, f: `${Math.round(longevity * 100)}%` },
              {
                v: runningOutOfMoney,
                f: `${Math.round(runningOutOfMoney * 100)}%`,
              },
            ],
          });
        } else {
          this.networthChart.dataTable.rows.push({
            c: [
              { v: startYear, f: startYear },
              { v: netWorth, f: `£ ${Math.round(netWorth / 1000) * 1000}` },
              { v: longevity, f: `${Math.round(longevity * 100)}%` },
              {
                v: runningOutOfMoney,
                f: `${Math.round(runningOutOfMoney * 100)}%`,
              },
            ],
          });
        }
      }
      this.configureHAxisTicks();
      this.configureVAxisTicks();
      if (alternateChart) {
        this.networthChartAlternate.component.draw();
      } else {
        this.networthChart.component.draw();
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  configureVAxisTicks() {
    if (!this._scenarioData) {
      return;
    }
    let max = 0;
    let min = 0;
    if (
      this.networthChart &&
      this.networthChart.dataTable &&
      this.networthChart.dataTable.rows &&
      this.networthChart.dataTable.rows.length
    ) {
      max = this.networthChart.dataTable.rows
        .map((r) => Number(r.c[1].v))
        .reduce((a, b) => Math.max(a, b), 0);
      min = this.networthChart.dataTable.rows
        .map((r) => Number(r.c[1].v))
        .reduce((a, b) => Math.min(a, b), 0);
      this.networthChart.options['vAxis']['maxValue'] = max * 1.5;
      this.networthChart.options['vAxis']['minValue'] = min * 1.5;
    }
    if (
      this.networthChartAlternate &&
      this.networthChartAlternate.dataTable &&
      this.networthChartAlternate.dataTable.rows &&
      this.networthChartAlternate.dataTable.rows.length
    ) {
      max = Math.max(
        max,
        this.networthChartAlternate.dataTable.rows
          .map((r) => Number(r.c[1].v))
          .reduce((a, b) => Math.max(a, b), 0)
      );
      min = Math.max(
        max,
        this.networthChartAlternate.dataTable.rows
          .map((r) => Number(r.c[1].v))
          .reduce((a, b) => Math.min(a, b), 0)
      );
      this.networthChartAlternate.options['vAxis']['maxValue'] = max * 1.5;
      this.networthChartAlternate.options['vAxis']['minValue'] = min * 1.5;
    }
  }

  configureHAxisTicks() {
    if (!this._scenarioData) {
      return;
    }

    const minYear = new Date().getFullYear();
    const maxYear = this.getMaxSimulationDataYear(
      this._scenarioData[this._scenarioId]
    );

    const xAxisTicks = [];
    for (
      let i = minYear;
      i <= (this._duration === '120' ? maxYear - 5 : maxYear);
      i++
    ) {
      if (
        (this._duration === '120' && i % 10 === 0) ||
        this._duration === '5'
      ) {
        xAxisTicks.push(i);
      }
    }
    this.networthChart.options['hAxis']['ticks'] = xAxisTicks;
    this.networthChartAlternate.options['hAxis']['ticks'] = xAxisTicks;
  }

  private buildGradientOverlay(data: ScenarioData): void {
    const startYear: number = moment(data.startDate).year();
    const maxYear = this.getMaxSimulationDataYear(data) - startYear;
    const opacityStep = Math.floor(1000 / maxYear) - 2;
    let opacity = 0;
    let gradient = '';
    for (let i = 0; i < maxYear; i += 10) {
      opacity += opacityStep;
      const longevity = !data.data[i]
        ? 0
        : data.data[i][Constants.TOTAL_ALIVE] /
          data.data[i][Constants.TOTAL_LIVES];
      gradient += `, rgba(255,255,255, ${1.2 - longevity}) ${opacity}%`;
    }

    this.gradient = this.sanitizer.bypassSecurityTrustStyle(
      `linear-gradient(90deg${gradient} )`
    );
  }

  private getMaxSimulationDataYear(data: ScenarioData) {
    if (!data) {
      return moment().year();
    }
    let year: number = moment(data.startDate).year() + data.data.length;
    const maxYear: number =
      moment(data.startDate).year() + parseInt(this._duration);

    for (let i = data.data.length - 1; ; i--) {
      if (
        data.data[i] &&
        data.data[i][Constants.TOTAL_ALIVE] >
          Constants.MINIMUM_LIVES_ALIVE_TO_SHOW
      ) {
        break;
      }
      year--;
    }

    return Math.min(year, maxYear);
  }

  private filterPastGoals(goalData: any[]) {
    const primaryRetGoal = goalData.find(
      (g) => g.data.type === 'RetirementGoal'
    )?.data;
    if (primaryRetGoal) {
      const retirementYear = moment(primaryRetGoal?.endDate).year();
      if (retirementYear < new Date().getFullYear()) {
        goalData = goalData.filter((g) => g.data !== primaryRetGoal);
      }
    }
    if (this._partner) {
      const partnerRetGoal = goalData.find(
        (g) =>
          g.data.description &&
          JSON.parse(g.data.description).type === 'PARTNER_RETIREMENT'
      )?.data;
      if (!partnerRetGoal) return goalData;
      const retirementYear = moment(partnerRetGoal.endDate).year();
      if (retirementYear < new Date().getFullYear()) {
        goalData = goalData.filter((g) => g.data !== partnerRetGoal);
      }
    }
    return goalData;
  }

  positionGoals = () => {
    if (
      !this._goalData ||
      !this._scenarioId ||
      !this._goalData[this._scenarioId]
    ) {
      return;
    }
    this._goalData[this._scenarioId] = this.filterPastGoals(
      this._goalData[this._scenarioId]
    );
    const layoutInterface = this.networthChart.component.wrapper
      .getChart()
      .getChartLayoutInterface();

    const overlappingGoals = {};

    const baseZIndex = 600;
    const alternateZIndex = 30;
    this.positionGridLines();
    this.positionAxesLabels();
    if (
      this._alternateScenarioId &&
      this._goalData[this._alternateScenarioId]
    ) {
      this._goalData[this._alternateScenarioId] = this.filterPastGoals(
        this._goalData[this._alternateScenarioId]
      );
      this._goalData[this._alternateScenarioId].forEach((g) => {
        const year = moment(g.data.startDate).format('YYYY');

        if (overlappingGoals[year] === undefined) {
          overlappingGoals[year] = 0;
        }
        overlappingGoals[year]++;

        const topOffset = (overlappingGoals[year] - 1) * 65;
        g.style = {
          icon: GoalUtils.shortToIcon(g.data.type),
          top: `-${topOffset}px`,
          left: layoutInterface.getXLocation(year) - 16 + 'px',
          zIndex:
            (this.diffSliderValue >= 50 ? alternateZIndex : baseZIndex) +
            3 * overlappingGoals[year],
        };
      });
    }

    this._goalData[this._scenarioId].forEach((g) => {
      const year = moment(g.data.startDate).format('YYYY');

      if (overlappingGoals[year] === undefined) {
        overlappingGoals[year] = 0;
      }
      overlappingGoals[year]++;

      const topOffset = (overlappingGoals[year] - 1) * 75;
      g.style = {
        icon: GoalUtils.shortToIcon(g.data.type),
        top: `${topOffset}px`,
        left: layoutInterface.getXLocation(year) - 16 + 'px',
        zIndex:
          (this.diffSliderValue <= 50 ? baseZIndex : alternateZIndex) +
          3 * overlappingGoals[year],
      };
    });
  };

  positionGridLines() {
    if (!this._scenarioData || !this._scenarioData.startDate) {
      return;
    }
    const startYear = Number(
      moment(this._scenarioData[this._scenarioId].startDate).format('YYYY')
    );
    const maxYear =
      this.getMaxSimulationDataYear(this._scenarioData[this._scenarioId]) -
      startYear;

    const layoutInterface = this.networthChart.component.wrapper
      .getChart()
      .getChartLayoutInterface();
    this.verticalLines = [];
    for (let i = startYear; i <= startYear + maxYear; i++) {
      if (
        (this._duration === '120' && i % 10 === 0) ||
        this._duration === '5'
      ) {
        this.verticalLines.push({
          leftOffset: `${layoutInterface.getXLocation(i) - 16 + 40}px`,
        });
      }
    }

    this.horizontalLines = [];
    this.horizontalLines.push({ topOffset: '145px' });
    this.horizontalLines.push({ topOffset: '255px' });
  }

  positionAxesLabels() {
    if (!this._scenarioData) {
      return;
    }
    const layoutInterface = this.networthChart.component.wrapper
      .getChart()
      .getChartLayoutInterface();

    const minYear = new Date().getFullYear();
    const maxYear = this.getMaxSimulationDataYear(
      this._scenarioData[this._scenarioId]
    );

    // const zoneWidth = this._wantZone.nativeElement.offsetWidth;
    //y = ax + b with points (minYear, 100), (maxYear, 100 + zoneWidth)
    // const a = zoneWidth / (maxYear - minYear);
    // const b = 100 + zoneWidth - maxYear * a;

    this._graphYearList = [];
    const xAxisTicks = [];
    for (
      let i = minYear;
      i <= (this._duration === '120' ? maxYear - 5 : maxYear);
      i++
    ) {
      if (
        (this._duration === '120' && i % 10 === 0) ||
        this._duration === '5'
      ) {
        xAxisTicks.push(i);
        this._graphYearList.push({
          year: i,
          primaryAge: this._primary ? i - this._primary.yearOfBirth : 0,
          partnerAge: this._partner ? i - this._partner.yearOfBirth : 0,
          childrenAges: (this._children || []).map((c) => i - c.yearOfBirth),
          leftOffset: `${layoutInterface.getXLocation(i) - 7}px`,
        });
      }
    }
    // this.networthChart.options['hAxis']['ticks'] = [2020, 2024, 2027];
  }

  goToImprovementsScenario$ = () => {
    if (
      this._scenarios.find((s) => s.id === this._scenarioId).name ===
      Constants.IMPROVEMENTS_SCENARIO_NAME
    ) {
      return of({});
    }
    return of({})
      .pipe(
        concatMap(() =>
          iif(
            () =>
              this._scenarios.filter(
                (s) => s.name === Constants.IMPROVEMENTS_SCENARIO_NAME
              ).length > 0,
            of({}),
            this.cloneCurrentScenario$(Constants.IMPROVEMENTS_SCENARIO_NAME)
          )
        )
      )
      .pipe(
        concatMap(() =>
          this.setScenarioCurrent$(
            this._scenarios.find(
              (s) => s.name === Constants.IMPROVEMENTS_SCENARIO_NAME
            )
          )
        )
      );
  };

  diagnose = (goal: any) => {
    this._loading = true;
    if (goal.data.id.indexOf('PARTNER') >= 0) {
      goal = this.goalData.find(
        (d) =>
          d.data.type === 'RetirementGoal' && d.data.id.indexOf('PARTNER') < 0
      );
    }
    this.scenarioService
      .diagnose(this._scenarioId, goal.data.id)
      .pipe(
        mergeMap(() =>
          this.bandedGoalDiagnosticService.getByGoalIdAndBandId(
            this._scenarioId,
            goal.data.id,
            this.bandId,
            Constants.PAGE_ALL
          )
        )
      )
      .subscribe(
        (r) => {
          this._loading = false;
          this.dialog
            .open(DiagnosticDialogComponent, {
              width: '600px',
              data: {
                goal: goal.data,
                scenario: this._scenarios.find(
                  (s) => s.id === this._scenarioId
                ),
                achievability:
                  !goal.banded || !goal.banded.totalAlive
                    ? goal.overall
                    : goal.banded,
                diagnostics: r.content,
              },
            })
            .afterClosed()
            .subscribe((a) => {
              if (!a) {
                return;
              }
              this._loading = true;
              this.goToImprovementsScenario$()
                .pipe(
                  concatMap(() =>
                    a.action.action(
                      this.diagnosticsContextFactory.create(
                        this._scenarios.find((s) => s.id === this._scenarioId),
                        a.diagnostic
                      )
                    )
                  )
                )
                .pipe(mergeMap(() => this.collectData$()))
                .pipe(
                  mergeMap(() =>
                    this.executeSimulation$(
                      this._scenarios.find((s) => s.id === this._scenarioId)
                    )
                  )
                )
                .pipe(mergeMap(() => this.queryResults$(this._scenarioId)))
                .subscribe(
                  () => {
                    this._loading = false;
                    this.buildGraph(this._scenarioId);
                    this.buildGraph(this._alternateScenarioId, true);
                    this.buildGradientOverlay(
                      this._scenarioData[this._scenarioId]
                    );
                  },
                  (err) => {
                    this._loading = false;
                    this.notifierService.notify(
                      Constants.ERROR,
                      'Failed to apply fixes'
                    );
                  },
                  () =>
                    this.notifierService.notify(
                      Constants.SUCCESS,
                      'Fixes applied'
                    )
                );
            });
        },
        () => {
          this._loading = false;
          this.notifierService.notify(
            Constants.ERROR,
            'Failed to diagnose goal'
          );
        }
      );
  };

  openSolverDialog(mode: string) {
    this._loading = true;
    this.solverService.get(this._scenarioId).subscribe((res) => {
      this._loading = false;
      this.dialog
        .open(SolverDialogComponent, {
          height: '400px',
          width: '400px',
          data: { actions: res.actions, mode },
        })
        .afterClosed()
        .subscribe((res) => {
          if (res) {
            this.solver(mode);
          }
        });
    }),
      () => {
        this._loading = false;
        this.notifierService.notify(Constants.ERROR, 'Solver error');
      };
  }

  solver(mode: string) {
    this._loading = true;
    let clonedScenario: Scenario = null;
    this.scenarioService
      .clone({
        name: `Updated Scenario ${mode}`,
        id: this._scenarioId,
      })
      .pipe(
        concatMap((s) => {
          clonedScenario = s;
          return this.preferenceService.update(
            clonedScenario.id,
            Constants.PREFERENCE_KEY_INITIALIZED,
            'true'
          );
        }),
        concatMap(() => {
          return this.solverService.get(clonedScenario.id);
        }),
        concatMap(async (a) => {
          this._actions = a;
          return this.applyAllIncomeActionsToScenario$(
            this._actions,
            clonedScenario,
            mode
          );
        }),
        concatMap(async () => {
          return this.applyAllExpenseActionsToScenario$(
            this._actions,
            clonedScenario,
            mode
          );
        }),
        concatMap(async () => {
          return this.applyAllLiabilitiesActionsToScenario$(
            this._actions,
            clonedScenario,
            mode
          );
        }),
        concatMap(() => this.scenarioService.replace(clonedScenario)),
        concatMap(() => this.scenarioService.query(Constants.PAGE_ALL)),
        concatMap((resp) => {
          this._scenarios = resp.content;
          this._alternateScenarioId = this._scenarios.filter(
            (s) => s.id !== this._scenarioId
          )[0].id;
          this.otherScenarioId = this._alternateScenarioId;
          this.onSetAsCurrentScenarioTemp();
          return this.executeSimulation$(clonedScenario);
        }),
        concatMap(() => this.queryResults$(clonedScenario.id)),
        concatMap(() => this.setScenarioCurrent$(clonedScenario)),
        concatMap(() =>
          this.scenarioService.diff(this.baseScenarioId, this.otherScenarioId)
        ),
        tap((diff) => {
          this._scenarioDiff = diff;
        })
      )
      .subscribe(
        () => {
          this.buildGraph(this._scenarioId, false);
          this.buildGraph(clonedScenario.id, true);
          this.buildGradientOverlay(this._scenarioData[this._scenarioId]);
          this.positionGoals();
        },
        () => {
          this._loading = false;
          this.notifierService.notify(
            Constants.ERROR,
            'Failed to fetch actions'
          );
        },
        () => {
          this._loading = false;
          this.notifierService.notify(
            Constants.SUCCESS,
            'Actions applied to cloned scenario'
          );
        }
      );
  }

  applyAllIncomeActionsToScenario$(
    actions: Actions,
    scenario: Scenario,
    mode: string
  ) {
    const incomeAction = actions.actions.find(
      (a) => a.type === ActionType.INCOMES_ADJUSTMENT
    )?.amount;
    if (!incomeAction) {
      return of({});
    }
    const allIncomes = [];
    let earnedIncomes: EarnedIncome[] = [];
    let unearnedIncomes: UnearnedIncome[] = [];
    let rentIncomes: RentIncome[] = [];
    let investmentProperties: InvestmentProperty[] = [];
    let actionAmount: number = incomeAction;
    let remainingActionAmount = 0;
    const rentActionYield: RentAction[] = [];
    let sumOfAllIncomes = 0;
    let sumOfAllEarned = 0;
    let sumOfAllUnearned = 0;
    let sumOfAllRent = 0;
    const percentagedActions: PercentagedAction[] = [];
    return this.earnedIncomeService
      .queryForPerson(scenario.id, this._primary.id, Constants.PAGE_ALL)
      .pipe(
        concatMap((earned) => {
          earnedIncomes = earned.content;
          if (earnedIncomes.length > 0) {
            earnedIncomes.forEach((i) => {
              allIncomes.push(i.name);
            });
          }
          return this.unearnedIncomeService.queryForPerson(
            scenario.id,
            this._primary.id,
            Constants.PAGE_ALL
          );
        }),
        concatMap((unearned) => {
          unearnedIncomes = unearned.content;
          if (unearnedIncomes.length > 0) {
            unearnedIncomes.forEach((i) => {
              allIncomes.push(i.name);
            });
          }
          return this.rentIncomeService.queryForPerson(
            scenario.id,
            this._primary.id,
            Constants.PAGE_ALL
          );
        }),
        concatMap((rent) => {
          rentIncomes = rent.content;
          if (rentIncomes.length > 0) {
            rentIncomes.forEach((i) => {
              allIncomes.push(i.name);
            });
          }
          return iif(
            () => rentIncomes.length > 0,
            this.propertyService.queryInvestmentProperties(
              scenario.id,
              Constants.PAGE_ALL
            ),
            of(null)
          );
        }),
        map((investment) => {
          investmentProperties = investment?.content;
          if (mode === 'uniform') {
            if (allIncomes.length > 0) {
              actionAmount = incomeAction / allIncomes.length;
            }
            if (rentIncomes.length > 0) {
              rentIncomes.forEach((i) => {
                let tempRentActionId: string;
                let tempRentActionYield: number;
                investmentProperties.forEach((p) => {
                  if (i.propertyAssetId === p.id) {
                    tempRentActionId = i.id;
                    tempRentActionYield =
                      actionAmount +
                        i.annualNetRentalYieldPercentage * p.value >
                      p.value / 12
                        ? 1
                        : actionAmount / p.value +
                          i.annualNetRentalYieldPercentage;
                    remainingActionAmount +=
                      tempRentActionYield === 1
                        ? actionAmount -
                          (1 - i.annualNetRentalYieldPercentage) * p.value
                        : 0;
                  }
                  rentActionYield.push({
                    yield: tempRentActionYield,
                    rentId: tempRentActionId,
                  });
                });
              });
            }
            if (allIncomes.length > rentIncomes.length) {
              actionAmount =
                actionAmount +
                this.abs(remainingActionAmount) /
                  (allIncomes.length - rentIncomes.length);
            } else {
              actionAmount = incomeAction - this.abs(remainingActionAmount);
            }
          } else {
            if (earnedIncomes.length > 0) {
              sumOfAllEarned = earnedIncomes.reduce((a, b) => a + b.amount, 0);
            }
            if (unearnedIncomes.length > 0) {
              sumOfAllUnearned = unearnedIncomes.reduce(
                (a, b) => a + b.amount,
                0
              );
            }
            if (rentIncomes.length > 0) {
              rentIncomes.forEach((i) => {
                investmentProperties.forEach((p) => {
                  if (i.propertyAssetId === p.id) {
                    sumOfAllRent += i.annualNetRentalYieldPercentage * p.value;
                  }
                });
              });
            }
            sumOfAllIncomes = sumOfAllEarned + sumOfAllUnearned + sumOfAllRent;
            if (rentIncomes.length > 0) {
              const tempRentIncomes = [...rentIncomes];
              let index = 0;
              while (index < tempRentIncomes.length) {
                const currentRent: RentIncome = tempRentIncomes[index];
                let tempRentActionYield: number;
                let tempRentPercentage: number;
                remainingActionAmount = 0;
                investmentProperties.forEach((p) => {
                  if (currentRent.propertyAssetId === p.id) {
                    tempRentPercentage =
                      (currentRent.annualNetRentalYieldPercentage * p.value) /
                      sumOfAllIncomes;
                    tempRentActionYield =
                      actionAmount * tempRentPercentage +
                        currentRent.annualNetRentalYieldPercentage * p.value >
                      p.value / 12
                        ? 1
                        : (actionAmount * tempRentPercentage) / p.value +
                          currentRent.annualNetRentalYieldPercentage;
                    if (tempRentActionYield === 1) {
                      actionAmount -=
                        (1 - currentRent.annualNetRentalYieldPercentage) *
                        p.value;
                      sumOfAllIncomes -=
                        currentRent.annualNetRentalYieldPercentage * p.value;
                    }
                  }
                });
                if (rentActionYield?.find((a) => a.rentId === currentRent.id)) {
                  rentActionYield.find(
                    (a) => a.rentId === currentRent.id
                  ).yield = tempRentActionYield;
                } else {
                  rentActionYield.push({
                    rentId: currentRent.id,
                    yield: tempRentActionYield,
                  });
                }
                if (tempRentActionYield === 1) {
                  tempRentIncomes.splice(index, 1);
                  index = 0;
                } else {
                  index++;
                }
              }
            }
            earnedIncomes.forEach((i) => {
              percentagedActions.push({
                entityId: i.id,
                percentage: i.amount / sumOfAllIncomes,
              });
            });
            unearnedIncomes.forEach((i) => {
              percentagedActions.push({
                entityId: i.id,
                percentage: i.amount / sumOfAllIncomes,
              });
            });
          }
        }),
        concatMap(() => {
          return this.applySolverActionToEarnedIncome$(
            scenario,
            actionAmount,
            earnedIncomes,
            allIncomes,
            percentagedActions
          );
        }),
        concatMap(() => {
          return iif(
            () => unearnedIncomes.length > 0,
            this.applySolverActionToUnearnedIncome$(
              scenario,
              actionAmount,
              unearnedIncomes,
              percentagedActions
            ),
            of({})
          );
        }),
        concatMap(() => {
          return iif(
            () => rentIncomes.length > 0,
            this.applySolverActionToRentIncome$(
              scenario,
              rentActionYield,
              rentIncomes
            ),
            of({})
          );
        })
      )
      .subscribe();
  }

  applySolverActionToEarnedIncome$(
    scenario: Scenario,
    actionAmount: number,
    earned: EarnedIncome[],
    allIncomes: unknown[],
    percentagedActions: PercentagedAction[]
  ) {
    let incomeFrequency = 1;
    if (earned.length > 0) {
      return from(earned).pipe(
        concatMap((i) => {
          if (i.frequency === Frequency.ANNUALLY) {
            incomeFrequency = 1;
          } else if (i.frequency === Frequency.MONTHLY) {
            incomeFrequency = 12;
          } else if (i.frequency === Frequency.WEEKLY) {
            incomeFrequency = 52;
          }
          const weight: number =
            percentagedActions?.find((p) => p.entityId === i.id)?.percentage ||
            1;
          return this.earnedIncomeService.updateForPerson(
            scenario.id,
            this._primary.id,
            {
              ...i,
              amount: i.amount + (actionAmount * weight) / incomeFrequency,
            }
          );
        })
      );
    } else if (earned.length === 0 && allIncomes.length === 0) {
      return this.earnedIncomeService.createForPerson(
        scenario.id,
        this._primary.id,
        new EarnedIncomeBuilder()
          .name('My income')
          .currency(
            Constants.LOCALE_CONFIG[
              localStorage.getItem(Constants.LOCAL_STORAGE_LOCALE)
            ].currency
          )
          .amount(actionAmount)
          .startDate(DateUtils.thisYear())
          .endDate(DateUtils.thisYear())
          .endsOn(DateRangeType.ON_RETIREMENT)
          .frequency(Frequency.ANNUALLY)
          .growthRate(BaseGrowthRate.CALCULATED)
          .build()
      );
    } else {
      return of({});
    }
  }

  applySolverActionToUnearnedIncome$(
    scenario: Scenario,
    actionAmount: number,
    unearned: UnearnedIncome[],
    percentagedActions: PercentagedAction[]
  ) {
    let incomeFrequency = 1;
    return from(unearned).pipe(
      concatMap((i) => {
        if (i.frequency === Frequency.ANNUALLY) {
          incomeFrequency = 1;
        } else if (i.frequency === Frequency.MONTHLY) {
          incomeFrequency = 12;
        } else if (i.frequency === Frequency.WEEKLY) {
          incomeFrequency = 52;
        }
        const weight: number =
          percentagedActions?.find((p) => p.entityId === i.id)?.percentage || 1;
        return this.unearnedIncomeService.updateForPerson(
          scenario.id,
          this._primary.id,
          {
            ...i,
            amount: i.amount + (actionAmount * weight) / incomeFrequency,
          }
        );
      })
    );
  }

  applySolverActionToRentIncome$(
    scenario: Scenario,
    rentActionYield: RentAction[],
    rent: RentIncome[]
  ) {
    return from(rent).pipe(
      concatMap((i) => {
        return this.rentIncomeService.updateForPerson(
          scenario.id,
          this._primary.id,
          {
            ...i,
            annualNetRentalYieldPercentage: rentActionYield.find(
              (a) => a.rentId === i.id
            ).yield,
          }
        );
      })
    );
  }

  applyAllExpenseActionsToScenario$(
    actions: Actions,
    scenario: Scenario,
    mode: string
  ) {
    const expenseAction = actions.actions.find(
      (a) => a.type === ActionType.EXPENSES_ADJUSTMENT
    )?.amount;
    if (!expenseAction) {
      return of({});
    }
    const allExpenses = [];
    let livingExpenses: LivingExpense[] = [];
    let lifestyleExpenses: LivingExpense[] = [];
    let rentExpenses: RentExpense[] = [];
    let actionAmount: number = expenseAction;
    let sumOfLivingExpenses = 0;
    let sumOfLifestyleExpenses = 0;
    let sumOfRentExpenses = 0;
    let sumOfAllExpenses = 0;
    const percentagedActions: PercentagedAction[] = [];
    return this.livingExpenseService
      .query(scenario.id, Constants.PAGE_ALL)
      .pipe(
        concatMap((living) => {
          livingExpenses = living.content.filter(
            (e) => e.nonDiscretionaryPercentage === 1
          );
          lifestyleExpenses = living.content.filter(
            (e) => e.nonDiscretionaryPercentage === 0
          );
          if (livingExpenses.length > 0) {
            livingExpenses.forEach((e) => {
              allExpenses.push(e.name);
            });
          }
          if (lifestyleExpenses.length > 0) {
            lifestyleExpenses.forEach((e) => {
              allExpenses.push(e.name);
            });
          }
          return this.rentExpenseService.query(scenario.id, Constants.PAGE_ALL);
        }),
        map((rent) => {
          rentExpenses = rent.content;
          if (rentExpenses.length > 0) {
            rentExpenses.forEach((e) => {
              allExpenses.push(e.name);
            });
          }
          if (mode === 'uniform') {
            if (allExpenses.length > 0) {
              actionAmount = expenseAction / allExpenses.length;
            }
          } else {
            sumOfLivingExpenses =
              livingExpenses?.reduce(
                (a, b) => a + this.convertAmountToAnnual(b.amount, b.frequency),
                0
              ) || 0;
            sumOfLifestyleExpenses =
              lifestyleExpenses?.reduce(
                (a, b) => a + this.convertAmountToAnnual(b.amount, b.frequency),
                0
              ) || 0;
            sumOfRentExpenses =
              rentExpenses?.reduce(
                (a, b) => a + this.convertAmountToAnnual(b.amount, b.frequency),
                0
              ) || 0;
            sumOfAllExpenses =
              sumOfLivingExpenses + sumOfLifestyleExpenses + sumOfRentExpenses;
            livingExpenses?.forEach((e) => {
              percentagedActions.push({
                entityId: e.id,
                percentage:
                  this.convertAmountToAnnual(e.amount, e.frequency) /
                  sumOfAllExpenses,
              });
            });
            lifestyleExpenses?.forEach((e) => {
              percentagedActions.push({
                entityId: e.id,
                percentage:
                  this.convertAmountToAnnual(e.amount, e.frequency) /
                  sumOfAllExpenses,
              });
            });
            rentExpenses?.forEach((e) => {
              percentagedActions.push({
                entityId: e.id,
                percentage:
                  this.convertAmountToAnnual(e.amount, e.frequency) /
                  sumOfAllExpenses,
              });
            });
          }
        }),
        concatMap(() => {
          return iif(
            () => livingExpenses.length > 0,
            this.applySolverActionToLivingExpense$(
              scenario,
              actionAmount,
              livingExpenses,
              percentagedActions
            ),
            of({})
          );
        }),
        concatMap(() => {
          return iif(
            () => lifestyleExpenses.length > 0,
            this.applySolverActionToLivingExpense$(
              scenario,
              actionAmount,
              lifestyleExpenses,
              percentagedActions
            ),
            of({})
          );
        }),
        concatMap(() => {
          return iif(
            () => rentExpenses.length > 0,
            this.applySolverActionToRentExpense$(
              scenario,
              actionAmount,
              rentExpenses,
              percentagedActions
            ),
            of({})
          );
        })
      )
      .subscribe();
  }

  applySolverActionToLivingExpense$(
    scenario: Scenario,
    actionAmount: number,
    expenses: LivingExpense[],
    percentagedActions: PercentagedAction[]
  ) {
    let expenseFrequency = 1;
    return from(expenses).pipe(
      concatMap((e) => {
        if (e.frequency === Frequency.ANNUALLY) {
          expenseFrequency = 1;
        } else if (e.frequency === Frequency.MONTHLY) {
          expenseFrequency = 12;
        } else if (e.frequency === Frequency.WEEKLY) {
          expenseFrequency = 52;
        }
        const weight =
          percentagedActions?.find((a) => a.entityId === e.id)?.percentage || 1;
        return this.livingExpenseService.update(scenario.id, {
          ...e,
          amount:
            (actionAmount * weight) / expenseFrequency < e.amount
              ? e.amount + (actionAmount * weight) / expenseFrequency
              : 0,
        });
      })
    );
  }

  applySolverActionToRentExpense$(
    scenario: Scenario,
    actionAmount: number,
    expenses: RentExpense[],
    percentagedActions: PercentagedAction[]
  ) {
    let expenseFrequency = 1;
    return from(expenses).pipe(
      concatMap((e) => {
        if (e.frequency === Frequency.ANNUALLY) {
          expenseFrequency = 1;
        } else if (e.frequency === Frequency.MONTHLY) {
          expenseFrequency = 12;
        } else if (e.frequency === Frequency.WEEKLY) {
          expenseFrequency = 52;
        }
        const weight =
          percentagedActions?.find((a) => a.entityId === e.id)?.percentage || 1;
        return this.rentExpenseService.update(scenario.id, {
          ...e,
          amount:
            (actionAmount * weight) / expenseFrequency < e.amount
              ? e.amount + (actionAmount * weight) / expenseFrequency
              : 0,
        });
      })
    );
  }

  applyAllLiabilitiesActionsToScenario$(
    actions: Actions,
    scenario: Scenario,
    mode: string
  ) {
    const liabilityAction = actions.actions.find(
      (a) => a.type === ActionType.LIABILITIES_ADJUSTMENT
    )?.amount;
    if (!liabilityAction) {
      return of({});
    }
    const allLiabilities = [];
    const creditCardDebt: UnsecuredLiability[] = [];
    const carLoan: UnsecuredLiability[] = [];
    const studentLoan: UnsecuredLiability[] = [];
    const otherLiability: UnsecuredLiability[] = [];
    let unsecuredLiability: UnsecuredLiability[] = [];
    let mortgageLiability: MortgageLiability[] = [];
    let actionAmount: number = liabilityAction;
    let sumOfAllCreditCardDebt = 0;
    let sumOfAllCarLoans = 0;
    let sumOfAllStudentLoans = 0;
    let sumOfAllOtherLiabilities = 0;
    let sumOfAllMortgage = 0;
    let sumOfAllLiabilities = 0;
    const percentagedActions: PercentagedAction[] = [];
    return this.unsecuredLiabilityService
      .query(scenario.id, Constants.PAGE_ALL)
      .pipe(
        concatMap((unsecured) => {
          unsecuredLiability = unsecured.content;
          if (unsecuredLiability.length > 0) {
            unsecuredLiability.forEach((l) => {
              if (l.description === LiabilityTypes.CREDIT_CARD) {
                creditCardDebt.push(l);
              } else if (l.description === LiabilityTypes.STUDENT_LOAN) {
                studentLoan.push(l);
              } else if (l.description === LiabilityTypes.CAR_LOAN) {
                carLoan.push(l);
              } else {
                otherLiability.push(l);
              }
              if (mode === 'uniform') {
                allLiabilities.push(l.name);
              }
            });
          }
          return this.mortgageLiabilityService.query(
            scenario.id,
            Constants.PAGE_ALL
          );
        }),
        map((mortgage) => {
          mortgageLiability = mortgage.content;
          if (mortgageLiability.length > 0) {
            mortgageLiability.forEach((m) => {
              if (mode === 'uniform') {
                allLiabilities.push(m.name);
              } else {
                sumOfAllMortgage += m.amount;
              }
            });
          }
          if (mode === 'uniform') {
            if (allLiabilities.length > 0) {
              actionAmount = liabilityAction / allLiabilities.length;
            }
          } else {
            sumOfAllCreditCardDebt = creditCardDebt?.reduce(
              (a, b) => a + b.amount,
              0
            );
            sumOfAllCarLoans = carLoan?.reduce((a, b) => a + b.amount, 0);
            sumOfAllStudentLoans = studentLoan?.reduce(
              (a, b) => a + b.amount,
              0
            );
            sumOfAllOtherLiabilities = otherLiability?.reduce(
              (a, b) => a + b.amount,
              0
            );
            sumOfAllLiabilities =
              sumOfAllCreditCardDebt +
              sumOfAllCarLoans +
              sumOfAllStudentLoans +
              sumOfAllOtherLiabilities +
              sumOfAllMortgage;
            mortgageLiability?.forEach((m) => {
              percentagedActions.push({
                entityId: m.id,
                percentage: m.amount / sumOfAllLiabilities,
              });
            });
            creditCardDebt?.forEach((l) => {
              percentagedActions.push({
                entityId: l.id,
                percentage: l.amount / sumOfAllLiabilities,
              });
            });
            carLoan?.forEach((l) => {
              percentagedActions.push({
                entityId: l.id,
                percentage: l.amount / sumOfAllLiabilities,
              });
            });
            studentLoan?.forEach((l) => {
              percentagedActions.push({
                entityId: l.id,
                percentage: l.amount / sumOfAllLiabilities,
              });
            });
            otherLiability?.forEach((l) => {
              percentagedActions.push({
                entityId: l.id,
                percentage: l.amount / sumOfAllLiabilities,
              });
            });
          }
        }),
        concatMap(() => {
          return iif(
            () => unsecuredLiability.length > 0,
            this.applySolverActionToUnsecuredLiability$(
              scenario,
              actionAmount,
              unsecuredLiability,
              percentagedActions
            ),
            of({})
          );
        }),
        concatMap(() => {
          return iif(
            () => mortgageLiability.length > 0,
            this.applySolverActionToMortgageLiability$(
              scenario,
              actionAmount,
              mortgageLiability,
              percentagedActions
            ),
            of({})
          );
        })
      )
      .subscribe();
  }

  applySolverActionToUnsecuredLiability$(
    scenario: Scenario,
    actionAmount: number,
    liabilities: UnsecuredLiability[],
    percentagedActions: PercentagedAction[]
  ) {
    return from(liabilities).pipe(
      concatMap((l) => {
        const weight =
          percentagedActions?.find((a) => a.entityId === l.id)?.percentage || 1;
        return this.unsecuredLiabilityService.update(scenario.id, {
          ...l,
          amount:
            l.amount + actionAmount * weight >= 0
              ? l.amount + actionAmount * weight
              : 0,
        });
      })
    );
  }

  applySolverActionToMortgageLiability$(
    scenario: Scenario,
    actionAmount: number,
    mortgages: MortgageLiability[],
    percentagedActions: PercentagedAction[]
  ) {
    return from(mortgages).pipe(
      concatMap((m) => {
        const weight =
          percentagedActions?.find((a) => a.entityId === m.id)?.percentage || 1;
        return this.mortgageLiabilityService.update(scenario.id, {
          ...m,
          amount:
            m.amount + actionAmount * weight >= 0
              ? m.amount + actionAmount * weight
              : 0,
        });
      })
    );
  }

  convertAmountToAnnual(amount: number, frequency: string) {
    if (frequency === Frequency.MONTHLY) {
      return amount * 12;
    } else if (frequency === Frequency.WEEKLY) {
      return amount * 52;
    } else {
      return amount;
    }
  }

  onResize() {
    this.configureHAxisTicks();
    this.networthChart.component.draw();
  }

  onResizeAlternate() {
    this.configureHAxisTicks();
    this.networthChartAlternate.component.draw();
  }

  updateDuration = (button) => {
    this._duration = button.value;
    this._loading = true;
    this._goalData[this._scenarioId] = [];
    this._goalData[this._alternateScenarioId] = [];
    concat(...this._scenarios.map((s) => this.queryResults$(s.id)))
      .pipe(finalize(() => (this._loading = false)))
      .subscribe(() => {
        this.buildGraph(this._scenarioId);
        this.buildGraph(this._alternateScenarioId, true);
        this.buildGradientOverlay(this._scenarioData[this._scenarioId]);
        this.positionGoals();
      });
  };

  edit = (goal: any) => {
    const isPartnerRetirement =
      goal.data.description &&
      JSON.parse(goal.data.description).type === 'PARTNER_RETIREMENT';

    this.analyticsService.trackAnalyticsEvent(
      AnalyticsEvent.EDIT_GOAL,
      goal.data.type,
      {
        [AnalyticsProperty.GOAL_TYPE]: goal.data.type,
      }
    );
    this.typedGoalService
      .get(
        this._scenarioId,
        isPartnerRetirement
          ? this._typedGoals.find((g) => g.type === 'RETIREMENT').id
          : goal.data.id
      )
      .subscribe((g: Goal) =>
        this.dialog
          .open(EditGoalDialogComponent, {
            width: '300px',
            data: {
              goal: isPartnerRetirement
                ? {
                    ...g,
                    description: JSON.stringify({ type: 'PARTNER_RETIREMENT' }),
                    priority: Constants.NEED_PRIORITY,
                    name: "Partner's retirement",
                    id: g.id + '_PARTNER',
                    startDate: DateUtils.atYear(
                      this._partner.yearOfBirth +
                        this._partner.expectedRetirementAge
                    ),
                    endDate: DateUtils.atYear(
                      this._partner.yearOfBirth +
                        this._partner.expectedRetirementAge
                    ),
                  }
                : g,
              scenarioId: this._scenarioId,
              scenario: this._scenarios.find((s) => s.id === this._scenarioId),
            },
          })
          .afterClosed()
          .subscribe((updated) => {
            if (!updated) {
              return;
            }
            this._loading = true;
            this._goalData = {};
            this.refreshResults$()
              .pipe(
                catchError((err) => {
                  this.notifierService.notify(
                    Constants.ERROR,
                    'Error editing goal'
                  );
                  this._loading = false;
                  return of({});
                })
              )
              .pipe(finalize(() => (this._loading = false)))
              .subscribe(() => {
                this.buildGraph(this._scenarioId);
                this.buildGradientOverlay(this._scenarioData[this._scenarioId]);
                this.positionGoals();
              });
          })
      );
  };

  updateBand = (button) => {
    this.analyticsService.trackAnalyticsEvent(
      AnalyticsEvent.CHANGE_BAND,
      button.value
    );
    this._bandId = button.value;
    this._loading = true;
    this._goalData[this._scenarioId] = [];
    concat(...this._scenarios.map((s) => this.queryResults$(s.id)))
      .pipe(finalize(() => (this._loading = false)))
      .subscribe(
        () => {
          this.buildGraph(this._scenarioId);
          this.buildGraph(this._alternateScenarioId, true);
          this.buildGradientOverlay(this._scenarioData[this._scenarioId]);
        },
        () => {
          this._loading = false;
          this.notifierService.notify(Constants.ERROR, 'Failed to update band');
        }
      );
  };

  getAchievability = (
    banded: GoalAchievability,
    overall: GoalAchievability
  ): string => {
    const achievability: GoalAchievability =
      !banded || !banded.totalAlive ? overall : banded;
    return !achievability ||
      !achievability.totalAlive ||
      !achievability.totalAchieved
      ? '0'
      : (
          (achievability.totalAchieved * 100) /
          achievability.totalAlive
        ).toFixed(0);
  };

  public getAchievabilityColor(
    banded: GoalAchievability,
    overall: GoalAchievability
  ): string {
    const achievability: GoalAchievability =
      !banded || !banded.totalAlive ? overall : banded;
    const percentage: number =
      !achievability ||
      !achievability.totalAlive ||
      !achievability.totalAchieved
        ? 0
        : (achievability.totalAchieved * 100) / achievability.totalAlive;

    if (percentage < Constants.DEFAULT_RESULTS_ACHIEVABILITY_THRESHOLDS[1]) {
      return 'red';
    }

    if (percentage >= Constants.DEFAULT_RESULTS_ACHIEVABILITY_THRESHOLDS[2]) {
      return 'green';
    }

    return 'amber';
  }

  getIncomes() {
    const incomesWithoutRent = this._incomes
      .map((i) => this.toAnnualFrequencyMultiplier(i.frequency) * i.amount)
      .reduce((a, b) => a + b, 0);
    const rentIncomes = this._rentIncomes
      .map((i) => {
        if (!this._assets.find((a) => a.id === i.propertyAssetId)) {
          return 0;
        }
        return (
          i.annualNetRentalYieldPercentage *
          this._assets.find((a) => a.id === i.propertyAssetId).value
        );
      })
      .reduce((a, b) => a + b, 0);
    return incomesWithoutRent + rentIncomes;
  }

  getExpenses() {
    return this._expenses
      .map((e) => this.toAnnualFrequencyMultiplier(e.frequency) * e.amount)
      .reduce((a, b) => a + b, 0);
  }

  getAssets() {
    return this._assets.map((i) => i.value).reduce((a, b) => a + b, 0);
  }

  getLiabilities() {
    return this._liabilities.map((i) => i.amount).reduce((a, b) => a + b, 0);
  }

  getInsurances() {
    return this._insurances.map((i) => i.payout).reduce((a, b) => a + b, 0);
  }

  showRegistrationPopup = () =>
    this.dialog.open(UpgradeDialogComponent, {
      width: '450px',
      height: '250px',
    });

  toAnnualFrequencyMultiplier(frequency: Frequency): number {
    switch (frequency) {
      case Frequency.DAILY:
        return 365;
      case Frequency.WEEKLY:
        return 52;
      case Frequency.MONTHLY:
        return 12;
      case Frequency.QUARTERLY:
        return 4;
      case Frequency.SEMI_ANNUALLY:
        return 2;
      case Frequency.ANNUALLY:
      case Frequency.ONE_OFF:
      default:
        return 1;
    }
  }

  showLinkToBubelePopup = () => {
    if (
      'true' === localStorage.getItem(Constants.LOCAL_STORAGE_BUBELE_REGISTERED)
    ) {
      return;
    }

    this.dialog.open(LinkToBubeleDialogComponent, {
      width: '520px',
      height: '340px',
      data: {
        scenario: this._scenarios.find((s) => s.id === this._scenarioId),
      },
    });
  };

  get primary() {
    return this._primary;
  }

  get partner() {
    return this._partner;
  }

  get graphYearList() {
    return this._graphYearList;
  }

  get graphData() {
    return this._graphData;
  }

  get goalData() {
    if (!this._scenarioId || !this._goalData[this._scenarioId]) {
      return [];
    }
    if (this._duration === '5') {
      return this._goalData[this._scenarioId].filter(
        (g) =>
          new Date(g.data.startDate).getFullYear() <=
          new Date().getFullYear() + 5
      );
    }
    return this._goalData[this._scenarioId].filter((g) =>
      g.overall
        ? g.overall.totalAlive > Constants.MINIMUM_LIVES_ALIVE_TO_SHOW
        : null
    );
  }

  get alternateScenarioGoalData() {
    if (
      !this._alternateScenarioId ||
      !this._goalData[this._alternateScenarioId]
    ) {
      return [];
    }
    if (this._duration === '5') {
      return this._goalData[this._alternateScenarioId].filter(
        (g) =>
          new Date(g.data.startDate).getFullYear() <=
          new Date().getFullYear() + 5
      );
    }
    return this._goalData[this._alternateScenarioId].filter((g) =>
      g.overall
        ? g.overall.totalAlive > Constants.MINIMUM_LIVES_ALIVE_TO_SHOW
        : null
    );
  }

  get bandId() {
    return this._bandId;
  }

  set bandId(bandId: string) {
    this._bandId = bandId;
  }

  get loading() {
    return this._loading;
  }

  get featureAdvisor() {
    return AppFeatureType.Advisor;
  }

  get featureSolverUniformAction() {
    return AppFeatureType.SolverUniformAction;
  }

  get featureSolverWeightedAction() {
    return AppFeatureType.SolverWeightedAction;
  }

  get registered() {
    return GuestUtils.registered();
  }

  // 'scenarios' is used as a template variable so we need a different name
  get otherScenarios() {
    return this._scenarios.filter(
      (s) => !s.household.preferences[Constants.PREFERENCE_KEY_CURRENT]
    );
  }

  get whatIfScenarios() {
    return this._scenarios;
  }

  get allScenarios() {
    return this._scenarios;
  }

  get currentScenario() {
    return (
      this._scenarios.find((s) => s.id === this._scenarioId) || { name: '' }
    );
  }

  get featureMoneyHubAccounts() {
    return AppFeatureType.MoneyHubAccounts;
  }

  toggleDrawer() {
    this.drawerVisible = !this.drawerVisible;
    setTimeout((_) => {
      this.onResize();
      this.onResizeAlternate();
    }, 200);
  }

  onScenarioChange(event: MatSelectChange) {
    this._loading = true;
    this._alternateScenarioId = this.otherScenarioId;
    this._scenarioId = this.baseScenarioId;
    this.scenarioService
      .diff(this.baseScenarioId, this.otherScenarioId)
      .subscribe(
        (diff) => {
          this._scenarioDiff = diff;
          this._loading = false;
          this.buildGraph(this._scenarioId);
          this.buildGraph(this._alternateScenarioId, true);
          this.buildGradientOverlay(this._scenarioData[this._scenarioId]);
          this.positionGoals();
        },
        () => {
          this._loading = false;
          this.notifierService.notify(
            Constants.ERROR,
            'Failed to compare scenarios'
          );
        }
      );
  }

  private onSetAsCurrentScenarioTemp() {
    this._scenarios.forEach((s) => {
      if (s.id === this.currentScenario.id) {
        s.household.preferences[Constants.PREFERENCE_KEY_CURRENT] = true;
      } else {
        s.household.preferences[Constants.PREFERENCE_KEY_CURRENT] = false;
      }
    });
  }

  get currency() {
    return Constants.LOCALE_CONFIG[this._locale].currencySymbol;
  }

  get incomesDiff() {
    return ScenarioUtils.getIncomesDiff(this._scenarioDiff);
  }

  get expensesDiff() {
    return ScenarioUtils.getExpensesDiff(this._scenarioDiff);
  }

  get assetsDiff() {
    return ScenarioUtils.getAssetsDiff(this._scenarioDiff);
  }

  get liabilitiesDiff() {
    return ScenarioUtils.getLiabilitiesDiff(this._scenarioDiff);
  }

  get insurancesDiff() {
    return ScenarioUtils.getInsurancesDiff(this._scenarioDiff);
  }

  get isaUsageDiff() {
    return ScenarioUtils.getIsaUsageDiff(this._scenarioDiff);
  }

  get wrapperAllocationDiff() {
    return ScenarioUtils.getWrapperAllocationDiff(
      this._scenarioDiff,
      this._assetWrappers,
      this._assetAllocationPresets
    );
  }

  get isaUsageDiffText(): string {
    const isaDiff: { active: boolean }[] = ScenarioUtils.getIsaUsageDiff(
      this._scenarioDiff
    );

    if (!isaDiff || isaDiff[0].active === isaDiff[1].active) {
      return null;
    }

    return isaDiff[0].active
      ? Constants.DIFF_TEXT_ISA_ENABLED
      : Constants.DIFF_TEXT_ISA_DISABLED;
  }

  public abs = (val: number) => Math.abs(val);

  get duration() {
    return this._duration;
  }

  set duration(duration: string) {
    this._duration = duration;
  }
}
