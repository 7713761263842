import { OnInit, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { RegistrationData } from 'src/app/model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { RealmUserService } from 'src/app/services/realm.user.service';
import { AppConfigService } from 'src/app/services/app.config.service';

@Component({
  selector: 'app-upgrade-dialog',
  templateUrl: './upgrade.dialog.component.html',
  styleUrls: ['./upgrade.dialog.component.scss'],
})
export class UpgradeDialogComponent implements OnInit {
  loading: boolean;
  registrationForm: FormGroup;
  siteKey: string;
  formError: string;

  constructor(
    private formBuilder: FormBuilder,
    private realmUserService: RealmUserService,
    private configService: AppConfigService,
    private t: TranslateService,
    private dialogRef: MatDialogRef<UpgradeDialogComponent>
  ) {
    this.registrationForm = formBuilder.group(new RegistrationData());
  }

  ngOnInit() {
    this.loading = false;
    this.registrationForm = this.formBuilder.group({
      emailAddress: [
        '',
        Validators.compose([Validators.required, Validators.email]),
      ],
    });
    this.formError = '';
  }

  cancel = () => this.dialogRef.close();

  onSubmit() {
    if (this.registrationForm.invalid) {
      this.formError = this.t.instant('Please fill the form correctly');
      this.registrationForm.get('emailAddress').markAsTouched();
      return;
    }
    const upgradeReq = {
      emailAddress: this.registrationForm.get('emailAddress').value,
    };
    this.loading = true;
    this.realmUserService.upgradeGuest(upgradeReq).subscribe({
      next: (response) => {
        window.location.href = `${
          this.configService.getConfig().oAuthConfig['issuer']
        }/protocol/openid-connect/auth?response_type=code&client_id=${
          this.configService.getConfig().oAuthConfig['clientId']
        }&redirect_uri=${window.location.origin}&kc_action=UPDATE_PASSWORD`;
      },
      error: (error) => this.handleRegistrationError(error),
      complete: () => {
        this.loading = false;
      },
    });
  }

  handleRegistrationError(message: string) {
    if (message.indexOf('409') > 0) {
      this.formError = 'This email address is already in use';
    } else {
      this.formError = message;
    }
  }
}
