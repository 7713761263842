import { Injectable } from '@angular/core';
import { AnalyticsEvent, AnalyticsProperty } from '../model/analytics';

@Injectable()
export class AnalyticsService {
  constructor() {}

  trackAnalyticsEvent(
    eventName: AnalyticsEvent,
    label: any,
    gstCustom?: object
  ): void {
    let eventParams: any = {
      [AnalyticsProperty.LABEL]: label,
      ...gstCustom,
    };
    gtag('event', eventName, eventParams);
  }

  setAnalyticsUserProperty(property: AnalyticsProperty, value: any): void {
    gtag('set', 'user_properties', {
      [property]: value,
    });
  }
}
