import { MoneyhubService } from './services/moneyhub.service';
// -- modules
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './material.module';
import { FeatureModule } from './feature';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RecaptchaModule } from 'ng-recaptcha';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { DragulaModule } from 'ng2-dragula';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { CommonModule } from '@angular/common';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';

// -- services
import {
  HttpErrorHandler,
  MessageService,
  TermsService,
  PropertyService,
  DisabilityInsuranceService,
  LifeInsuranceService,
  CriticalIllnessInsuranceService,
  MortgageLifeInsuranceService,
  AccountService,
  PersonsService,
  MortgageLiabilityService,
  UnsecuredLiabilityService,
  EarnedIncomeService,
  UnearnedIncomeService,
  RentIncomeService,
  FinancialAssetsService,
  LivingExpenseService,
  PensionExpenseService,
  ScenarioService,
  ResultsOverallGoalAchievabilityService,
  ResultsBandedGoalAchievabilityService,
  ResultsScenarioDataService,
  ResultsScenarioGoalService,
  TypedGoalService,
  ResultsBandedGoalDiagnosticService,
  ResultsOverallGoalDiagnosticService,
  ResultsScenarioBandService,
  AuthService,
  IncomeService,
  ExpenseService,
  LiabilityService,
  InsuranceService,
  AssetService,
  EditGoalFormFactory,
  RentExpenseService,
  AnalyticsService,
  PensionContributionIncomeService,
  WizardService,
  OxfordRiskService,
  RealmUserService,
} from './services';
import { AppConfigService, IConfig } from './services/app.config.service';
import { FeatureService } from './feature/feature.service';
import { PreferenceService } from './services/preference.service';
import { UserPropertyService } from './services/user.property.service';
import { IressPortfolioService } from './services/iress/portfolio.iress.service';
import { IressFumPortfolioService } from './services/iress/fum.portfolio.iress.service';
import { IressClientService } from './services/iress/client.iress.service';
import { IressSessionService } from './services/iress/session.iress.service';
import { IressPortfolioPositionService } from './services/iress/position.portfolio.iress.service';
import { SolverService } from './services/solver.service';

// -- components
import { AppComponent } from './app.component';
import { LandingComponent, WelcomeComponent } from './components/landing';
import {
  FamilyComponent,
  FamilyPersonComponent,
  BalanceComponent,
  BalanceAssetComponent,
  BalanceAssetPropertyComponent,
  BalanceLiabilityComponent,
  IncomeStatementComponent,
  IncomeComponent,
  IncomeRentComponent,
  ExpenseComponent,
  ConnectComponent,
  ProtectionComponent,
  ProtectionInsuranceComponent,
  FutureComponent,
  OnboardingContainerComponent,
  RiskDialogComponent,
  IRESSComponent,
  MoneyhubComponent,
  ConnectionsComponent,
} from './components/onboarding';
import {
  MenuComponent,
  DiagnosticDialogComponent,
  OverviewContainerComponent,
} from './components/overview';
import {
  YearPickerComponent,
  UpgradeDialogComponent,
  EditGoalRetirementComponent,
  ConnectedBankComponent,
  EditGoalEliminateDebtComponent,
  SpinnerComponent,
  ConnectedComponent,
  LinkToBubeleDialogComponent,
} from './components/common';
import { GraphComponent } from './components/overview/components/graph/graph.component';
import { EditGoalDialogComponent } from './components/overview/components/edit/edit.dialog.component';
import { DeleteScenarioDialogComponent } from './components/overview/components/delete-scenario/delete.scenario.dialog.component';

// --common
import { AuthGuard } from './guards/auth.guard';
import { MoneyhubAuthGuard } from './guards/moneyhub.auth.guard';
import { HttpInterceptorProviders } from './interceptors';
import { Constants } from './app.constants';
import {
  MHAboutDialogComponent,
  MHCancelDialogComponent,
  MHConsentDialogComponent,
  MHRenewDialogComponent,
  BankAvatarComponent,
} from './components/common/moneyhub';

// -- directives
import {
  EditGoalBaseComponent,
  EditGoalSabbaticalComponent,
  EditGoalBuyHouseComponent,
  EditGoalBuyCarComponent,
} from './components/common/edit-goals';
import { ClickOutsideDirective } from './directives/clickOutside.directive';
import { ToPercentDirective } from './directives/percent.directive';
import { InputValidationDirective } from './directives/inputValidation.directive';
import { DiagnosticsContextFactory } from './services/diagnostics.context.factory';
import { MortgageFundingSourceComponent } from './components/common/funding-sources/mortgage-funding-source/mortgage-funding-source.component';
import {
  LiquidityFundingSourceComponent,
  OtherLoanFundingSourceComponent,
  ResidentialPropertyFundingSourceComponent,
  FutureInvestmentPropertyFundingSourceComponent,
} from './components/common/funding-sources';
import { StudentLoanFundingSourceComponent } from './components/common/funding-sources/student-loan-funding-source/student-loan-funding-source.component';
import { SaveFundingSourceComponent } from './components/common/funding-sources/save-funding-source/save-funding-source.component';
import { BankOfMumAndDadFundingSourceComponent } from './components/common/funding-sources/bank-mum-dad-funding-source/bank-mum-dad-funding-source.component';
import { AddFundingSourceDialogComponent } from './components/common/edit-goals/funding-source-dialog/funding.source.dialog.component';
import { InvestmentPropertyFundingSourceComponent } from './components/common/funding-sources/investment-property-funding-source/investment-property-funding-source.component';
import { FutureResidentialPropertyFundingSourceComponent } from './components/common/funding-sources/future-residential-property-funding-source/future-residential-property-funding-source.component';
import { FrequencyPickerComponent } from './components/common/frequency-picker/frequency-picker.component';
import { EditGoalRecurringComponent } from './components/common/edit-goals/edit-goal-recurring/edit-goal-recurring.component';
import { AssessmentComponent } from './components/onboarding/assessment/assessment.component';
import { WithLoadingPipe } from './pipes/withLoading.pipe';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { CurrencyMaskModule, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';
import { DragulaModule, DragulaService } from 'ng2-dragula';
import { FlexLayoutModule } from '@envizage/flex-layout';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AuthMonitorComponent } from './components/auth-monitor/auth-monitor.component';
import { SolverDialogComponent } from './components/overview/components/graph/solver-dialog/solver-dialog.component';

const notifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12,
    },
    vertical: {
      position: 'top',
      distance: 12,
    },
  },
  theme: 'material',
};

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
  // [
  //   { prefix: './assets/i18n/', suffix: '.json' },
  // ]
  // return new TranslateHttpLoader(http, 'projects/auvious-bot/src/lib/assets/i18n/');
}

export function initAppWithConfig(
  configService: AppConfigService,
  translate: TranslateService
) {
  window['dataLayer'] = window['dataLayer'] || [];
  window['gtag'] = (...args) => {
    window['dataLayer'].push(args);
  };

  return (): Promise<any> => {
    return configService
      .resolve()
      .toPromise()
      .then((config: IConfig) => {
        if (
          config.trackingIds &&
          config.trackingIds.length &&
          config.trackingIds.length > 0
        ) {
          const script = document.createElement('script');
          script.src =
            'https://www.googletagmanager.com/gtag/js?id=' +
            config.trackingIds[0];
          const head = document.getElementsByTagName('head')[0];
          head.appendChild(script);
          gtag('js', new Date());

          config.trackingIds.forEach((t) => gtag('config', t));
        }
        translate.setDefaultLang('en');
        translate.use('en');
        localStorage.setItem(Constants.LOCAL_STORAGE_LOCALE, config.locale);
        return new Promise((resolve) => {
          translate.onLangChange.subscribe(() => {
            resolve(null);
          });
        });
      });
  };
}

const currencyMaskConfigFactory = (configService: AppConfigService) => {
  return {
    ...Constants.DEFAULT_CURRENCY_MASK_CONFIG,
    prefix:
      Constants.LOCALE_CONFIG[configService.getConfig().locale].currencySymbol +
      ' ',
  };
};

@NgModule({
  declarations: [
    WithLoadingPipe,
    GraphComponent,
    SolverDialogComponent,
    MenuComponent,
    FrequencyPickerComponent,
    ResidentialPropertyFundingSourceComponent,
    InvestmentPropertyFundingSourceComponent,
    FutureResidentialPropertyFundingSourceComponent,
    FutureInvestmentPropertyFundingSourceComponent,
    AddFundingSourceDialogComponent,
    BankOfMumAndDadFundingSourceComponent,
    SaveFundingSourceComponent,
    StudentLoanFundingSourceComponent,
    OtherLoanFundingSourceComponent,
    MortgageFundingSourceComponent,
    LiquidityFundingSourceComponent,
    EditGoalBuyCarComponent,
    EditGoalBuyHouseComponent,
    EditGoalDialogComponent,
    DiagnosticDialogComponent,
    UpgradeDialogComponent,
    LinkToBubeleDialogComponent,
    OverviewContainerComponent,
    AppComponent,
    LandingComponent,
    WelcomeComponent,
    OnboardingContainerComponent,
    FamilyComponent,
    FamilyPersonComponent,
    BalanceComponent,
    BalanceAssetComponent,
    BalanceAssetPropertyComponent,
    BalanceLiabilityComponent,
    IncomeStatementComponent,
    IncomeComponent,
    IncomeRentComponent,
    ExpenseComponent,
    ProtectionComponent,
    ProtectionInsuranceComponent,
    FutureComponent,
    EditGoalBaseComponent,
    EditGoalRecurringComponent,
    EditGoalRetirementComponent,
    EditGoalSabbaticalComponent,
    EditGoalEliminateDebtComponent,
    DeleteScenarioDialogComponent,
    YearPickerComponent,
    ClickOutsideDirective,
    RiskDialogComponent,
    ToPercentDirective,
    SpinnerComponent,
    ConnectComponent,
    ConnectedComponent,
    AssessmentComponent,
    MoneyhubComponent,
    IRESSComponent,
    MHAboutDialogComponent,
    MHCancelDialogComponent,
    MHConsentDialogComponent,
    MHRenewDialogComponent,
    BankAvatarComponent,
    ConnectionsComponent,
    ConnectedBankComponent,
    AuthMonitorComponent,
    RiskDialogComponent,
    AddFundingSourceDialogComponent,
    ResidentialPropertyFundingSourceComponent,
    InvestmentPropertyFundingSourceComponent,
    FutureResidentialPropertyFundingSourceComponent,
    FutureInvestmentPropertyFundingSourceComponent,
    DiagnosticDialogComponent,
    SaveFundingSourceComponent,
    BankOfMumAndDadFundingSourceComponent,
    StudentLoanFundingSourceComponent,
    OtherLoanFundingSourceComponent,
    MortgageFundingSourceComponent,
    LiquidityFundingSourceComponent,
    EditGoalBuyCarComponent,
    EditGoalDialogComponent,
    EditGoalBaseComponent,
    EditGoalRecurringComponent,
    EditGoalBuyHouseComponent,
    EditGoalRetirementComponent,
    EditGoalEliminateDebtComponent,
    EditGoalSabbaticalComponent,
    UpgradeDialogComponent,
    LinkToBubeleDialogComponent,
    DeleteScenarioDialogComponent,
    MHAboutDialogComponent,
    MHCancelDialogComponent,
    MHConsentDialogComponent,
    MHRenewDialogComponent,
  ],
  imports: [
    // DeviceDetectorModule.forRoot(),
    NotifierModule.withConfig(notifierOptions),
    DragAndDropModule,
    CommonModule,
    MatListModule,
    Ng2GoogleChartsModule,
    CurrencyMaskModule,
    MatTooltipModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    NotifierModule,
    ReactiveFormsModule,
    MaterialModule,
    RecaptchaModule,
    FlexLayoutModule,
    InputValidationDirective,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    FeatureModule,
    DragulaModule.forRoot(),
    OAuthModule.forRoot({
      resourceServer: {
        sendAccessToken: true,
      },
    }),
  ],
  providers: [
    AppConfigService,
    PensionContributionIncomeService,
    {
      provide: APP_INITIALIZER,
      useFactory: initAppWithConfig,
      deps: [AppConfigService, TranslateService],
      multi: true,
    },
    AnalyticsService,
    FeatureService,
    AccountService,
    AuthService,
    AuthGuard,
    MoneyhubAuthGuard,
    DisabilityInsuranceService,
    LifeInsuranceService,
    CriticalIllnessInsuranceService,
    MortgageLifeInsuranceService,
    FinancialAssetsService,
    TermsService,
    PreferenceService,
    HttpInterceptorProviders,
    HttpErrorHandler,
    MortgageLiabilityService,
    UnsecuredLiabilityService,
    MessageService,
    PropertyService,
    PersonsService,
    TermsService,
    IncomeService,
    ExpenseService,
    AssetService,
    LiabilityService,
    InsuranceService,
    EarnedIncomeService,
    UnearnedIncomeService,
    RentIncomeService,
    RentExpenseService,
    LivingExpenseService,
    PensionExpenseService,
    ScenarioService,
    ResultsScenarioGoalService,
    ResultsOverallGoalAchievabilityService,
    ResultsBandedGoalAchievabilityService,
    ResultsOverallGoalDiagnosticService,
    ResultsBandedGoalDiagnosticService,
    ResultsScenarioDataService,
    ResultsScenarioBandService,
    TypedGoalService,
    MoneyhubService,
    OxfordRiskService,
    IressSessionService,
    IressClientService,
    IressPortfolioService,
    IressFumPortfolioService,
    IressPortfolioPositionService,
    DiagnosticsContextFactory,
    EditGoalFormFactory,
    WizardService,
    UserPropertyService,
    DeviceDetectorService,
    DragulaService,
    RealmUserService,
    SolverService,
    {
      provide: CURRENCY_MASK_CONFIG,
      useFactory: currencyMaskConfigFactory,
      deps: [AppConfigService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
