import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgModel } from '@angular/forms';
import { InvestmentProperty } from 'src/app/model';
import { RentIncome } from 'src/app/model/household/income';

@Component({
  selector: 'app-income-rent',
  templateUrl: './income-rent.component.html',
  styleUrls: ['./income-rent.component.scss'],
})
export class IncomeRentComponent {
  @Input() income: RentIncome;
  @Input() investmentProperties: Array<InvestmentProperty>;

  @Output() update = new EventEmitter<RentIncome>();
  @Output() remove = new EventEmitter<RentIncome>();

  private _showInputForId: string;

  showNameInput(ref: HTMLInputElement) {
    setTimeout(() => ref.focus(), 50);
  }

  onUpdate(el: NgModel) {
    if (!this.income || el.value === null) {
      return;
    }

    const rentValue = el.value;
    const propValue = this.investmentProperties
      .filter((p) => p.id === this.income.propertyAssetId)
      .map((p) => p.value)[0];

    this.income.annualNetRentalYieldPercentage =
      Math.round((parseFloat(rentValue) * 12 * 100000) / propValue) / 100000;

    if (this.income.annualNetRentalYieldPercentage > 1) {
      el.control.markAsTouched();
      el.control.setErrors({ max: true });
      return;
    }
    this.update.emit(this.income);
  }

  onRemove() {
    this.remove.emit(this.income);
  }

  getMonthlyRent(income: RentIncome) {
    return this.investmentProperties
      .filter((i) => i.id === income.propertyAssetId)
      .map((p) => p.value)
      .map((val) => (income.annualNetRentalYieldPercentage * val) / 12)
      .map((val) => Math.round(val))[0];
  }

  getPropertyValue(income: RentIncome) {
    return this.investmentProperties.find(
      (i) => i.id === income.propertyAssetId
    ).value;
  }

  get showInputForId() {
    return this._showInputForId;
  }

  set showInputForId(id: string) {
    this._showInputForId = id;
  }
}
