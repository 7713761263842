// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coin-container {
  touch-action: none;
  -ms-touch-action: none;
  visibility: visible !important;
  transition: all 0.2s ease-out;
}
.coin-container.disabled {
  opacity: 0.3;
}

#dropzone_protections_2 .coin,
#dropzone_protections_1 .coin {
  display: none !important;
}

.disabled .coin {
  cursor: default;
}`, "",{"version":3,"sources":["webpack://./src/app/components/onboarding/protection/protection.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,sBAAA;EACA,8BAAA;EACA,6BAAA;AACF;AAAE;EACE,YAAA;AAEJ;;AAEA;;EAEE,wBAAA;AACF;;AAEA;EACE,eAAA;AACF","sourcesContent":[".coin-container {\n  touch-action: none;\n  -ms-touch-action: none;\n  visibility: visible !important;\n  transition: all 0.2s ease-out;\n  &.disabled {\n    opacity: 0.3;\n  }\n}\n\n#dropzone_protections_2 .coin,\n#dropzone_protections_1 .coin {\n  display: none !important;\n}\n\n.disabled .coin {\n  cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
