// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  border-bottom: 1px solid var(--nvz-separator-border);
}
:host:last-child {
  border-bottom: none;
}

.mat-mdc-form-field-error {
  visibility: hidden;
}
.mat-mdc-form-field-error.visible {
  visibility: visible;
}

.mat-mdc-form-field {
  padding: 0 10px 10px;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
mat-select {
  box-sizing: border-box;
  max-width: 100%;
}

.mat-icon {
  display: inline;
}`, "",{"version":3,"sources":["webpack://./src/app/components/onboarding/protection/insurance/insurance.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,oDAAA;AACF;AAAE;EACE,mBAAA;AAEJ;;AAEA;EACE,kBAAA;AACF;AACE;EACE,mBAAA;AACJ;;AAGA;EACE,oBAAA;AAAF;;AAGA,6HAAA;AACA;EACE,sBAAA;EACA,eAAA;AAAF;;AAGA;EACE,eAAA;AAAF","sourcesContent":[":host {\n  display: block;\n  border-bottom: 1px solid var(--nvz-separator-border);\n  &:last-child {\n    border-bottom: none;\n  }\n}\n\n.mat-mdc-form-field-error {\n  visibility: hidden;\n\n  &.visible {\n    visibility: visible;\n  }\n}\n\n.mat-mdc-form-field {\n  padding: 0 10px 10px;\n}\n\n/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */\nmat-select {\n  box-sizing: border-box;\n  max-width: 100%;\n}\n\n.mat-icon {\n  display: inline;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
