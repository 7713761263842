import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Constants } from 'src/app/app.constants';
import { IMoneyhubTransactionData } from 'src/app/model';
import { Income } from 'src/app/model/household/income/income';

interface IViewIncome extends Income {
  isFromLinkedAccounts: boolean;
}

@Component({
  selector: 'app-income',
  templateUrl: './income.component.html',
  styleUrls: ['./income.component.scss'],
})
export class IncomeComponent {
  @Input() income: IViewIncome;
  @Input() linkedIncomes: IMoneyhubTransactionData[];

  @Output() update = new EventEmitter<Income>();
  @Output() remove = new EventEmitter<Income>();
  @Output() updateLinked = new EventEmitter<Income>();
  @Output() removeLinked = new EventEmitter<Income>();

  private _showInputForId: string;

  showNameInput(ref: HTMLInputElement) {
    setTimeout(() => ref.focus(), 50);
  }

  onUpdate() {
    this.update.emit(this.income);
  }

  onUpdateLinked() {
    this.updateLinked.emit(this.income);
  }

  onRemove() {
    this.remove.emit(this.income);
  }

  onRemoveLinked() {
    this.removeLinked.emit(this.income);
  }

  getFrequencyLabel(frequency) {
    return Constants.FREQUENCY_LABELS.find((f) => f.id === frequency).label;
  }

  get showInputForId() {
    return this._showInputForId;
  }

  set showInputForId(id: string) {
    this._showInputForId = id;
  }

  get frequencies() {
    return Constants.SELECTABLE_FREQUENCIES;
  }

  get currentYear() {
    return new Date().getFullYear();
  }
}
