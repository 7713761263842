import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  signal,
} from '@angular/core';
import { Constants } from 'src/app/app.constants';
import {
  DisabilityInsurance,
  Insurance,
  InsuranceType,
  MortgageInsurance,
  MortgageLiability,
} from 'src/app/model';
import { EarnedIncome } from 'src/app/model/household/income';

@Component({
  selector: 'app-protection-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.scss'],
})
export class ProtectionInsuranceComponent implements OnInit {
  @Input() insurance: Insurance | MortgageInsurance | DisabilityInsurance;
  @Input() type: InsuranceType;
  @Input() earnedIncomes: EarnedIncome[] = [];
  @Input() mortgages: MortgageLiability[] = [];

  @Output() update = new EventEmitter<Insurance>();
  @Output() remove = new EventEmitter<Insurance>();

  private _showInputForId: string;

  isRemoveAvailable = false;
  isLife = false;
  isIllness = false;
  isDisability = false;
  isMortgage = false;
  icon: string;
  isEdit = signal(true);

  ngOnInit(): void {
    this.isRemoveAvailable = this.remove.observed;
    this.isLife = this.type === 'life';
    this.isIllness = this.type === 'illness';
    this.isDisability = this.type === 'disability';
    this.isMortgage = this.type === 'mortgage';
  }

  extractYearFromDate(d) {
    return new Date(d).getFullYear();
  }

  showNameInput(ref: HTMLInputElement) {
    setTimeout(() => ref.focus(), 50);
  }

  get showInputForId() {
    return this._showInputForId;
  }

  set showInputForId(id: string) {
    this._showInputForId = id;
  }

  get minYear() {
    return new Date().getFullYear();
  }

  get maxYear() {
    return new Date().getFullYear() + Constants.GOAL_YEAR_RANGE;
  }

  get incomeInsured() {
    return this.earnedIncomes.find((i) => i.id === this.insurance.description)
      .name;
  }

  get mortgageInsured() {
    return this.mortgages.find(
      (m) => m.id === (this.insurance as MortgageInsurance).mortgageId
    ).name;
  }

  onUpdate() {
    this.update.emit(this.insurance);
  }

  onRemove() {
    this.remove.emit(this.insurance);
  }

  onInsuranceYearChange(event, isStartDate) {
    if (isStartDate) {
      this.insurance.startDate = event;
    } else {
      this.insurance.endDate = event;
      this.insurance.endsOn = 'USER_DEFINED';
    }
    this.update.emit(this.insurance);
  }

  onInsuranceEndChange(event) {
    this.insurance.endsOn = event.value;
    this.update.emit(this.insurance);
  }
}
