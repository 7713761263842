// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panel {
  min-height: 300px;
}
@media screen and (max-width: 599px) {
  .panel {
    min-height: auto;
  }
}

.mat-form-row-pinned {
  background: #f7f8f9;
  border-radius: 4px;
}

#dropzone_2 .coin,
#dropzone_1 .coin {
  display: none !important;
}

.mat-mdc-form-field-error {
  visibility: hidden;
}
.mat-mdc-form-field-error.visible {
  visibility: visible;
}

.mat-mdc-form-field {
  padding: 0 10px 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/onboarding/family/family.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAAE;EAFF;IAGI,gBAAA;EAGF;AACF;;AAAA;EACE,mBAAA;EACA,kBAAA;AAGF;;AAAA;;EAEE,wBAAA;AAGF;;AACA;EACE,kBAAA;AAEF;AADE;EACE,mBAAA;AAGJ;;AACA;EACE,oBAAA;AAEF","sourcesContent":[".panel {\n  min-height: 300px;\n  @media screen and (max-width: 599px) {\n    min-height: auto;\n  }\n}\n\n.mat-form-row-pinned {\n  background: #f7f8f9;\n  border-radius: 4px;\n}\n\n#dropzone_2 .coin,\n#dropzone_1 .coin {\n  display: none !important;\n}\n\n\n.mat-mdc-form-field-error {\n  visibility: hidden;\n  &.visible {\n    visibility: visible;\n  }\n}\n\n.mat-mdc-form-field {\n  padding: 0 10px 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
