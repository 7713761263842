import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import * as moment from 'moment/moment';
import { DragulaService } from 'ng2-dragula';
import { Subscription, forkJoin, from, iif, of } from 'rxjs';
import { catchError, concatMap, finalize, flatMap, map } from 'rxjs/operators';
import { Constants } from 'src/app/app.constants';
import { AppFeatureType } from 'src/app/feature';
import { FeatureService } from 'src/app/feature/feature.service';
import {
  FinancialClass,
  FinancialPortfolio,
  FinancialWrapper,
  InvestmentProperty,
  LiabilityTypes,
  MoneyhubUser,
  MortgageLiability,
  MortgageType,
  Person,
  Property,
  RepaymentType,
  ResidentialProperty,
  UnsecuredLiability,
  FinancialPortfolioWithContributions,
} from 'src/app/model';
import { AnalyticsEvent, AnalyticsLabel } from 'src/app/model/analytics';
import { SavingsSequence } from 'src/app/model/financial/savings.sequence';
import { PensionExpense } from 'src/app/model/household/expense/pension.expense';
import {
  EarnedIncome,
  PensionContributionIncome,
  PensionContributionIncomeBuilder,
} from 'src/app/model/household/income';
import {
  AnalyticsService,
  EarnedIncomeService,
  PensionContributionIncomeService,
  PensionExpenseService,
  PropertyService,
  WizardService,
} from 'src/app/services';
import { AppConfigService } from 'src/app/services/app.config.service';
import { FinancialAssetsService } from 'src/app/services/financial.assets.service';
import {
  MortgageLiabilityService,
  UnsecuredLiabilityService,
} from 'src/app/services/liability.service';
import { MoneyhubService } from 'src/app/services/moneyhub.service';
import { PersonsService } from 'src/app/services/persons.service';
import { PreferenceService } from 'src/app/services/preference.service';
import { DateUtils } from 'src/app/utils';
import { ExpensesCalculatorUtils } from 'src/app/utils/expenses.calculator.utils';

@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.scss'],
})
export class BalanceComponent implements OnInit, OnDestroy {
  private _navigation: any;

  private _scenarioId: string;
  private _financialWrappers: FinancialWrapper[] = [];
  private _financialClasses: FinancialClass[] = [];
  private _mortgages: MortgageLiability[] = [];
  private _unsecuredLiabilities: UnsecuredLiability[] = [];
  private _residentialProperties: ResidentialProperty[] = [];
  private _investmentProperties: InvestmentProperty[] = [];
  private _primaryEarnedIncomes: EarnedIncome[] = [];
  private _partnerEarnedIncomes: EarnedIncome[] = [];
  private _primaryPensionIncomes: PensionContributionIncome[] = [];
  private _partnerPensionIncomes: PensionContributionIncome[] = [];
  private _primaryPensionExpenses: PensionExpense[] = [];
  private _partnerPensionExpenses: PensionExpense[] = [];
  private _primaryFinancialPortfolios: FinancialPortfolioWithContributions[] =
    [];
  private _partnerFinancialPortfolios: FinancialPortfolioWithContributions[] =
    [];
  private _properties: Property[] = [];
  private _savingsSequences: SavingsSequence[] = [];
  private _primary: Person;
  private _partner: Person;
  private _onDragEnd: Function;
  private _dragParam: any;
  private _dragCategory: string;
  public breakpointAssets: number;
  public breakpointsDebts: number;
  private _draggingCategory: string;
  private _assetAllocationPresets: {
    name: string;
    percentages: number[];
  }[] = [];
  private _loading = false;
  public totalAssets = 12;
  public totalDebts = 5;
  private _showInputForId: string;
  private _idAssetAllocationMatrix: number[][];
  private _wrapperAllocationPresets: {
    name: string;
    percentages: number[];
  }[] = [
    {
      name: Constants.DEFAULT_WRAPPER_ALLOCATION_NAME,
      percentages: [],
    },
  ];

  private _linkedResidentialProperties: ResidentialProperty[] = [];
  private _linkedInvestmentProperties: InvestmentProperty[] = [];
  private _linkedFinancialPortfolios: FinancialPortfolioWithContributions[] =
    [];

  private _linkedCreditCardLiabilities: UnsecuredLiability[] = [];
  private _linkedMortgages: MortgageLiability[] = [];

  private _propResidentialTmp: { [key: string]: any } = {};
  private _propInvestmentTmp: { [key: string]: any } = {};
  private _propSavingsTmp: { [key: string]: any } = {};
  private _propCashTmp: { [key: string]: any } = {};
  private _propDebtCardTmp: { [key: string]: any } = {};
  private _locale: string;
  public privatePensionAllocationPreset =
    Constants.DEFAULT_WRAPPER_ALLOCATION_NAME;
  public employerPensionAllocationPreset =
    Constants.DEFAULT_WRAPPER_ALLOCATION_NAME;
  public generalAllocationPreset = Constants.DEFAULT_WRAPPER_ALLOCATION_NAME;
  public isaAllocationPreset = Constants.DEFAULT_WRAPPER_ALLOCATION_NAME;
  private _subs: Subscription[] = [];
  private _scrollableAssets = true;
  private _scrollableLiabilities = true;

  private _onRefreshDone = {
    next: () => {
      this._loading = false;
    },
    error: (err) => {
      this._loading = false;
      this.notifier.notify(Constants.ERROR, 'Failed to refresh');
    },
  };
  private moneyhubUserId: string;

  private riskOrderMap = {
    cash: 0,
    low: 1,
    'medium-low': 2,
    moderate: 3,
    'medium-high': 4,
    high: 5,
  };

  public riskProfiles: {
    key: string;
    meanReturn: number;
  }[] = [];

  optionsOpen = false;

  constructor(
    private configService: AppConfigService,
    private dragulaService: DragulaService,
    private router: Router,
    private analyticsService: AnalyticsService,
    private titleService: Title,
    private preferenceService: PreferenceService,
    private t: TranslateService,
    private route: ActivatedRoute,
    private notifier: NotifierService,
    private propertyService: PropertyService,
    private financialService: FinancialAssetsService,
    private personsService: PersonsService,
    private pensionContributionIncomeService: PensionContributionIncomeService,
    private earnedIncomeService: EarnedIncomeService,
    private pensionExpenseService: PensionExpenseService,
    private mortgageLiabilityService: MortgageLiabilityService,
    private unsecuredLiabilityService: UnsecuredLiabilityService,
    private wizardService: WizardService,
    private moneyhubService: MoneyhubService,
    private featureService: FeatureService
  ) {
    this.dragulaService.createGroup('ASSETS', {
      direction: 'horizontal',
      copy: () => true,
      moves: (el) => !el.parentElement.classList.value.includes('disabled'),
      accepts: (el, target) => {
        return (
          target === document.getElementById('dropzone_assets_1') ||
          target === document.getElementById('dropzone_assets_2')
        );
      },
    });
    this.dragulaService.createGroup('LIABILITIES', {
      direction: 'horizontal',
      copy: () => true,
      moves: (el) => !el.parentElement.classList.value.includes('disabled'),
      accepts: (el, target) => {
        return (
          target === document.getElementById('dropzone_liabilities_1') ||
          target === document.getElementById('dropzone_liabilities_2')
        );
      },
    });
    this._subs.push(
      this.dragulaService
        .drop('ASSETS')
        .subscribe((el) => this.handleAssetDrop(el.source))
    );
    this._subs.push(
      this.dragulaService
        .drop('LIABILITIES')
        .subscribe((el) => this.handleLiabilityDrop(el.source))
    );
    this._subs.push(
      this.dragulaService
        .drag('ASSETS')
        .subscribe(() => (this._scrollableAssets = false))
    );
    this._subs.push(
      this.dragulaService
        .drag('LIABILITIES')
        .subscribe(() => (this._scrollableLiabilities = false))
    );

    this._subs.push(
      this.dragulaService
        .dragend('ASSETS')
        .subscribe(() => (this._scrollableAssets = true))
    );
    this._subs.push(
      this.dragulaService
        .dragend('LIABILITIES')
        .subscribe(() => (this._scrollableLiabilities = true))
    );
    this._subs.push(
      this.dragulaService.drop('ASSETS').subscribe((d) => {
        this._scrollableAssets = true;
      })
    );
    this._subs.push(
      this.dragulaService.drop('LIABILITIES').subscribe((d) => {
        this._scrollableLiabilities = true;
      })
    );
    window.addEventListener(
      'touchmove',
      (e) =>
        (!this._scrollableAssets || !this._scrollableLiabilities) &&
        e.preventDefault(),
      { passive: false }
    );
  }

  async ngOnInit() {
    this._locale = this.configService.getConfig().locale;
    this.totalAssets = this.financialAssets.length * 2 + 4; // include properties
    this.titleService.setTitle(this.t.instant('Onboarding | Balance'));
    this._loading = true;
    this._navigation = (this.route.data as any).value.navigation;
    this.onResize(null);
    this.refreshBalance();
    const user = MoneyhubUser.get();
    if (user) {
      this.moneyhubUserId = user.userId;
    }
    this._loading = false;
  }

  ngOnDestroy() {
    this.wizardService.setVisited(this.route.snapshot.url[0].path);
    this._subs.forEach((s) => s.unsubscribe());
    this.dragulaService.destroy('ASSETS');
    this.dragulaService.destroy('LIABILITIES');
  }

  get financialAssets(): [] {
    return Constants.LOCALE_CONFIG[this._locale].assetTypes;
  }

  handleLiabilityDrop(el: Element) {
    const inDropzone =
      document.getElementById('dropzone_liabilities_2').childNodes.length ===
        2 ||
      document.getElementById('dropzone_liabilities_1').childNodes.length === 2;
    if (inDropzone) {
      const nodeText = this.getDraggedElementText(el);
      switch (nodeText) {
        case 'Mortgage':
          this.createMortgage();
          break;
        case 'Credit Card':
          this.createCreditCardDebt();
          break;
        case 'Car loan':
          this.createCarLoanDebt();
          break;
        case 'Student loan':
          this.createStudentLoanDebt();
          break;
        case 'Other':
          this.createOtherDebt();
          break;
        default:
          break;
      }
    }

    //empty drop zones
    document
      .getElementById('dropzone_liabilities_1')
      .childNodes.forEach((c) => {
        if (
          c['classList'] &&
          c['classList'].item &&
          c['classList'].item(0) === 'coin'
        ) {
          c.remove();
        }
      });
    document
      .getElementById('dropzone_liabilities_2')
      .childNodes.forEach((c) => {
        if (
          c['classList'] &&
          c['classList'].item &&
          c['classList'].item(0) === 'coin'
        ) {
          c.remove();
        }
      });
  }

  getDraggedElementText(el: Element) {
    return el.childNodes[1].textContent.trim();
  }

  handleAssetDrop(el: Element) {
    const inDropzone =
      document.getElementById('dropzone_assets_2').childNodes.length === 2 ||
      document.getElementById('dropzone_assets_1').childNodes.length === 2;
    if (inDropzone) {
      const nodeText = this.getDraggedElementText(el);
      switch (nodeText) {
        case 'Residential property':
          this.createResidentialPropertyAsset();
          break;
        case 'Investment property':
          this.createInvestmentPropertyAsset();
          break;
        default:
          const person = nodeText.startsWith('Partner')
            ? this.partner
            : this.primary;
          const wrapper = Constants.LOCALE_CONFIG[
            this._locale
          ].assetTypes.filter((l) => nodeText.indexOf(l.label) >= 0)[0].wrapper;
          this.createFinancialAsset(person, wrapper, nodeText);
          break;
      }
    }

    //empty drop zones
    document.getElementById('dropzone_assets_2').childNodes.forEach((c) => {
      if (
        c['classList'] &&
        c['classList'].item &&
        c['classList'].item(0) === 'coin'
      ) {
        c.remove();
      }
    });
    document.getElementById('dropzone_assets_1').childNodes.forEach((c) => {
      if (
        c['classList'] &&
        c['classList'].item &&
        c['classList'].item(0) === 'coin'
      ) {
        c.remove();
      }
    });
  }

  onResize(event) {
    this.breakpointAssets =
      window.innerWidth < 599
        ? this.totalAssets
        : window.innerWidth <= 1100
          ? 1
          : 2;
    this.breakpointsDebts =
      window.innerWidth < 599
        ? this.totalDebts
        : window.innerWidth <= 1100
          ? 1
          : 2;
  }

  showNameInput(ref: HTMLInputElement) {
    setTimeout(() => ref.focus(), 50);
  }

  getGridWidth(total) {
    return window.innerWidth < 599 ? total * 90 + 'px' : 'auto';
  }

  get showInputForId() {
    return this._showInputForId;
  }

  set showInputForId(id: string) {
    this._showInputForId = id;
  }

  dragStart(dragCategory: string, onDragEnd: Function, ...params: any[]) {
    this._onDragEnd = onDragEnd;
    this._dragCategory = dragCategory;
    this._dragParam = params;
    this._draggingCategory = dragCategory;
  }

  dragEnd() {
    this._draggingCategory = null;
  }

  onDrop(category: string) {
    if (this._dragCategory === category) {
      this._onDragEnd(...this._dragParam);
    }
  }

  get savingSequences() {
    return this._savingsSequences.filter(
      (s) => s.id !== 'DISTRIBUTE_IN_CASH_PORTFOLIOS'
    );
  }

  updateSavingSequence(ev: MatSlideToggleChange, sequence) {
    this._loading = true;
    this._savingsSequences.find((s) => s.id === sequence.id).active =
      ev.checked;
    this.preferenceService
      .update(
        this._scenarioId,
        Constants.SAVINGS_SEQUENCE_PREFERENCE_NAME,
        this._savingsSequences
      )
      .pipe(
        flatMap(() =>
          this.preferenceService.query(this._scenarioId, Constants.PAGE_ALL)
        )
      )
      .subscribe(
        (resp) =>
          (this._savingsSequences = this.getPreference(
            resp.content,
            Constants.SAVINGS_SEQUENCE_PREFERENCE_NAME
          )),
        () => {
          this.notifier.notify(Constants.ERROR, 'Failed to update option');
          this._loading = false;
        },
        () => (this._loading = false)
      );
  }

  toggleISA() {
    const isa = this._savingsSequences.find((s) => s.wrapper === 'isa');
    if (!isa) {
      return;
    }
    isa.active = !isa.active;
    this._loading = true;

    this.preferenceService
      .update(
        this._scenarioId,
        Constants.SAVINGS_SEQUENCE_PREFERENCE_NAME,
        this._savingsSequences
      )
      .subscribe(
        () => {},
        () => this.notifier.notify(Constants.ERROR, 'Failed to update ISA'),
        () => {
          this.preferenceService
            .get(this._scenarioId, Constants.SAVINGS_SEQUENCE_PREFERENCE_NAME)
            .subscribe((r) => {
              this._savingsSequences = r;
              this.notifier.notify(Constants.SUCCESS, 'ISA settings updated');
              this._loading = false;
            });
        }
      );
  }

  get months() {
    return !!this._savingsSequences &&
      !!this._savingsSequences.find((s) => s.id === 'EMERGENCY_SAVINGS')
      ? {
          months: this._savingsSequences.find(
            (s) => s.id === 'EMERGENCY_SAVINGS'
          ).properties['timesMonthlyLivingExpenses'],
        }
      : { months: 6 };
  }

  get isaEnabled() {
    const isaSavings = this._savingsSequences.find((s) => s.wrapper === 'isa');
    if (!isaSavings) {
      return false;
    }
    return isaSavings.active;
  }

  createLinkedMortgageEntity(asset) {
    if (asset.type.startsWith('property') && asset.mortgage) {
      this._linkedMortgages.push({
        name: asset.mortgage.name,
        description: asset.name,
        source: Constants.SOURCE_MONEYHUB,
        amount: asset.mortgage.amount,
        currency: asset.mortgage.currency,
        startDate: new Date(asset.mortgage.startDate).toISOString(),
        endDate: new Date(asset.mortgage.endDate).toISOString(),
        repaymentType: RepaymentType.PRINCIPAL_AMORTIZATION,
        annualAverageInterestRate: asset.mortgage.fixedRateValue,
        balanceAmount: asset.mortgage.balanceAmount,
        balanceDate: new Date(asset.mortgage.balanceDate).toISOString(),
        mortgageType: asset.mortgage.fixedRate
          ? MortgageType.FIXED_RATE
          : MortgageType.ADJUSTABLE_RATE,
        propertyAssetId: null,
      });
    }
  }

  async getLinkedData() {
    try {
      this._loading = true;
      const data = await this.moneyhubService.getBalanceSheet().toPromise();
      this._linkedResidentialProperties = [];
      this._linkedInvestmentProperties = [];
      this._linkedFinancialPortfolios = [];
      this._linkedCreditCardLiabilities = [];
      this._showInputForId = null;

      data.assets.forEach((asset: any) => {
        switch (asset.type) {
          case 'property:residential':
            const propRes = this.residentialPropertyAssets.find(
              (a) =>
                a.description === Constants.SOURCE_MONEYHUB + ':' + asset.name
            );
            if (!!propRes) {
              // this._propResidentialTmp = { ...propRes };
              this._propResidentialTmp[propRes.description] = { ...propRes };
              propRes.value = asset.value;
              propRes.source = Constants.SOURCE_MONEYHUB;

              if (asset.mortgage) {
                const mortgageRes = this.mortgageDebts.find(
                  (m) => m.name === asset.mortgage.name
                );
                if (!!mortgageRes) {
                  this._propResidentialTmp[mortgageRes.description] = {
                    ...mortgageRes,
                  };
                  mortgageRes.amount = asset.mortgage.amount;
                  mortgageRes.source = Constants.SOURCE_MONEYHUB;
                  mortgageRes.endDate = new Date(
                    asset.mortgage.endDate
                  ).toISOString();
                  mortgageRes.annualAverageInterestRate =
                    asset.mortgage.fixedRateValue;
                }
              }
            } else {
              this._linkedResidentialProperties.push({
                primary: true,
                description: Constants.SOURCE_MONEYHUB + ':' + asset.name,
                source: Constants.SOURCE_MONEYHUB,
                country: asset.country,
                currency: asset.currency,
                name: asset.name,
                valuationDate: new Date(asset.valuationDate).toISOString(),
                value: asset.value,
              });
              this.createLinkedMortgageEntity(asset);
            }
            break;
          case 'property:investment':
            const propInv = this.investmentPropertyAssets.find(
              (a) =>
                a.description === Constants.SOURCE_MONEYHUB + ':' + asset.name
            );
            if (!!propInv) {
              this._propInvestmentTmp[propInv.description] = { ...propInv };
              // this._propInvestmentTmp = { ...propInv };
              propInv.value = asset.value;
              propInv.source = Constants.SOURCE_MONEYHUB;

              if (asset.mortgage) {
                const mortgageInv = this.mortgageDebts.find(
                  (m) => m.name === asset.mortgage.name
                );
                if (!!mortgageInv) {
                  this._propInvestmentTmp[mortgageInv.description] = {
                    ...mortgageInv,
                  };
                  mortgageInv.amount = asset.mortgage.amount;
                  mortgageInv.source = Constants.SOURCE_MONEYHUB;
                  mortgageInv.endDate = new Date(
                    asset.mortgage.endDate
                  ).toISOString();
                  mortgageInv.annualAverageInterestRate =
                    asset.mortgage.fixedRateValue;
                }
              }
            } else {
              this._linkedInvestmentProperties.push({
                country: asset.country,
                currency: asset.currency,
                name: asset.name,
                description: Constants.SOURCE_MONEYHUB + ':' + asset.name,
                source: Constants.SOURCE_MONEYHUB,
                valuationDate: new Date(asset.valuationDate).toISOString(),
                value: asset.value,
              });
              this.createLinkedMortgageEntity(asset);
            }
            break;
          case 'financial:pension':
          case 'financial:investment':
          case 'financial:savings':
            const propSav = this.primaryFinancialPortfolios.find(
              (a) =>
                a.portfolio.description ===
                Constants.SOURCE_MONEYHUB + ':' + asset.name
            );
            if (!!propSav) {
              this._propCashTmp[propSav.portfolio.description] = {
                ...propSav.portfolio,
              };
              propSav.portfolio.value = asset.value;
              propSav.portfolio.source = Constants.SOURCE_MONEYHUB;
            } else {
              this._linkedFinancialPortfolios.push({
                employerContribution: 0,
                privateContribution: 0,
                portfolio: {
                  name: asset.name,
                  profile: this._assetAllocationPresets[0].name,
                  wrapper: asset.wrapper,
                  source: Constants.SOURCE_MONEYHUB,
                  description: Constants.SOURCE_MONEYHUB + ':' + asset.name,
                  currency: asset.currency,
                  value: asset.value,
                  fees: Constants.DEFAULT_FINANCIAL_PORTFOLIO_CASH_FEES,
                },
              });
            }
            break;
          case 'financial:cash':
            const propCash = this.primaryFinancialPortfolios.find(
              (a) =>
                a.portfolio.description ===
                Constants.SOURCE_MONEYHUB + ':' + asset.name
            );
            if (!!propCash) {
              this._propCashTmp[propCash.portfolio.description] = {
                ...propCash.portfolio,
              };
              propCash.portfolio.value = asset.value;
              propCash.portfolio.source = Constants.SOURCE_MONEYHUB;
            } else {
              this._linkedFinancialPortfolios.push({
                employerContribution: 0,
                privateContribution: 0,
                portfolio: {
                  name: asset.name,
                  description: Constants.SOURCE_MONEYHUB + ':' + asset.name,
                  source: Constants.SOURCE_MONEYHUB,
                  profile: this._assetAllocationPresets.filter(
                    (p) => p.name === 'cash'
                  )[0].name,
                  wrapper: asset.wrapper,
                  currency: asset.currency,
                  value: asset.value,
                  fees: Constants.DEFAULT_FINANCIAL_PORTFOLIO_CASH_FEES,
                },
              });
            }
            break;
        }
      });
      data.liabilities.forEach((liab: any) => {
        switch (liab.type) {
          case 'debt:card':
            const debtCard = this.creditCardDebts.find((a) =>
              a.description.includes(
                Constants.SOURCE_MONEYHUB + ':' + liab.type
              )
            );
            if (!!debtCard) {
              this._propDebtCardTmp[debtCard.description] = { ...debtCard };
              // this._propDebtCardTmp = { ...debtCard };
              debtCard.amount = liab.amount;
              debtCard.source = Constants.SOURCE_MONEYHUB;
            } else {
              this._linkedCreditCardLiabilities.push({
                source: Constants.SOURCE_MONEYHUB,
                name: liab.name,
                amount: liab.amount,
                currency: liab.currency,
                description:
                  Constants.SOURCE_MONEYHUB + ':' + liab.type + ':' + liab.name,
                startDate: new Date(liab.startDate).toISOString(),
                endDate: new Date(liab.endDate).toISOString(),
                repaymentType: liab.repaymentType,
                annualAverageInterestRate: liab.annualAverageInterestRate,
              });
            }
            break;
        }
        // todo: do any parsing if necessary
      });
    } catch (ex) {
      // this.notifier.notify(Constants.ERROR, 'Could not retrieve linked data');
    } finally {
      this._loading = false;
    }
  }

  get hasLinkedData() {
    return (
      this._linkedResidentialProperties.length > 0 ||
      this._linkedInvestmentProperties.length > 0 ||
      this._linkedFinancialPortfolios.length > 0 ||
      this._linkedMortgages.length > 0 ||
      this._linkedCreditCardLiabilities.length > 0
    );
  }

  get hasConnectedAccounts() {
    return (
      !!localStorage.getItem(Constants.LOCAL_STORAGE_MONEYHUB_USER) &&
      !!localStorage.getItem(Constants.LOCAL_STORAGE_MONEYHUB_CONNECTIONS)
    );
  }

  refreshResidentialProperties = () =>
    this.$refreshResidentialProperties().subscribe(this._onRefreshDone);

  refreshInvestmentProperties = () =>
    this.$refreshInvestmentProperties().subscribe(this._onRefreshDone);

  refreshProperties = () =>
    this.$refreshProperties().subscribe(this._onRefreshDone);

  refreshPropertiesAndMortgages = () =>
    this.$refreshPropertiesAndMortgages().subscribe(this._onRefreshDone);

  refreshPreferences = () =>
    this.$refreshPreferences().subscribe(this._onRefreshDone);

  refreshMortgages = () =>
    this.$refreshMortgages().subscribe(this._onRefreshDone);

  refreshUnsecuredLiabilities = () =>
    this.$refreshUnsecuredLiabilities().subscribe(this._onRefreshDone);

  refreshPrimaryEarnedIncomes = () =>
    this.$refreshPrimaryEarnedIncomes().subscribe(this._onRefreshDone);

  refreshPrimaryPensionContributionExpenses = () =>
    this.$refreshPrimaryPensionContributionExpenses().subscribe(
      this._onRefreshDone
    );

  refreshPrimaryPensionContributionIncomes = () =>
    this.$refreshPrimaryPensionContributionIncomes().subscribe(
      this._onRefreshDone
    );

  refreshPartnerEarnedIncomes = () =>
    this.$refreshPartnerEarnedIncomes().subscribe(this._onRefreshDone);

  refreshPartnerPensionContributionExpenses = () =>
    this.$refreshPartnerPensionContributionExpenses().subscribe(
      this._onRefreshDone
    );

  refreshPartnerPensionContributionIncomes = () =>
    this.$refreshPartnerPensionContributionIncomes().subscribe(
      this._onRefreshDone
    );

  refreshFinancialClassesAndWrappers = () =>
    this.$refreshFinancialClassesAndWrappers().subscribe(this._onRefreshDone);

  refreshPrimaryFinancialAssets = () =>
    this.$refreshPrimaryPensionContributionExpenses()
      .pipe(flatMap(() => this.$refreshPrimaryPensionContributionIncomes()))
      .pipe(flatMap(() => this.$refreshPrimaryEarnedIncomes()))
      .pipe(flatMap(() => this.$refreshPrimaryFinancialAssets()))
      .subscribe(this._onRefreshDone);

  refreshPartnerFinancialAssets = () =>
    this.$refreshPartnerPensionContributionExpenses()
      .pipe(flatMap(() => this.$refreshPartnerPensionContributionIncomes()))
      .pipe(flatMap(() => this.$refreshPartnerEarnedIncomes()))
      .pipe(flatMap(() => this.$refreshPartnerFinancialAssets()))
      .subscribe(this._onRefreshDone);

  $refreshPreferences = () =>
    this.preferenceService.query(this._scenarioId, Constants.PAGE_ALL).pipe(
      map((r) => {
        this._savingsSequences = this.getPreference(
          r.content,
          Constants.SAVINGS_SEQUENCE_PREFERENCE_NAME
        );

        this._idAssetAllocationMatrix = this.getPreference(
          r.content,
          Constants.ID_ASSET_ALLOCATION_MATRIX_PREFERENCE_NAME
        );
        if (r) {
          const privatePensionWrapperIdx = this._financialWrappers.findIndex(
            (w) => w.id === Constants.FINANCIAL_WRAPPER_ID_PRIVATE_PENSION
          );
          const employerPensionWrapperIdx = this._financialWrappers.findIndex(
            (w) => w.id === Constants.FINANCIAL_WRAPPER_ID_EMPLOYER_PENSION
          );
          const isaWrapperIdx = this._financialWrappers.findIndex(
            (w) => w.id === Constants.FINANCIAL_WRAPPER_ID_ISA
          );
          const generalWrapperIdx = this._financialWrappers.findIndex(
            (w) => w.id === Constants.FINANCIAL_WRAPPER_ID_GIA
          );
          this.privatePensionAllocationPreset =
            r[privatePensionWrapperIdx] && r[privatePensionWrapperIdx].length
              ? this.calculateClosestAllocation(r[privatePensionWrapperIdx])
              : Constants.DEFAULT_WRAPPER_ALLOCATION_NAME;
          this.employerPensionAllocationPreset =
            r[employerPensionWrapperIdx] && r[employerPensionWrapperIdx].length
              ? this.calculateClosestAllocation(r[employerPensionWrapperIdx])
              : Constants.DEFAULT_WRAPPER_ALLOCATION_NAME;
          this.isaAllocationPreset =
            r[isaWrapperIdx] && r[isaWrapperIdx].length
              ? this.calculateClosestAllocation(r[isaWrapperIdx])
              : Constants.DEFAULT_WRAPPER_ALLOCATION_NAME;
          this.generalAllocationPreset =
            r[generalWrapperIdx] && r[generalWrapperIdx].length
              ? this.calculateClosestAllocation(r[generalWrapperIdx])
              : Constants.DEFAULT_WRAPPER_ALLOCATION_NAME;
        }

        const presetMatrix = this.getPreference(
          r.content,
          Constants.ASSET_ALLOCATION_PRESETS_PREFERENCE_NAME
        );

        this._assetAllocationPresets = [];
        this._wrapperAllocationPresets = [
          {
            name: Constants.DEFAULT_WRAPPER_ALLOCATION_NAME,
            percentages: [],
          },
        ];

        const returnMatrix = this.getPreference(
          r.content,
          Constants.REFERENCE_DATA_ASSET_RETURN_MATRIX_PREFERENCE_NAME
        );

        Object.keys(presetMatrix).forEach((preset) => {
          this._assetAllocationPresets.push({
            name: preset,
            percentages: presetMatrix[preset],
          });
          this._wrapperAllocationPresets.push({
            name: preset,
            percentages: presetMatrix[preset],
          });
          returnMatrix &&
            this.riskProfiles.push({
              key: preset,
              meanReturn: this.calculateMeanReturn(
                presetMatrix[preset],
                returnMatrix
              ),
            });
        });
      })
    );

  $refreshMortgages = () =>
    this.mortgageLiabilityService
      .query(this._scenarioId, Constants.PAGE_ALL)
      .pipe(map((r) => (this._mortgages = r.content)));

  $refreshUnsecuredLiabilities = () =>
    this.unsecuredLiabilityService
      .query(this._scenarioId, Constants.PAGE_ALL)
      .pipe(map((r) => (this._unsecuredLiabilities = r.content)));

  $refreshResidentialProperties = () =>
    this.propertyService
      .queryResidentialProperties(this._scenarioId, Constants.PAGE_ALL)
      .pipe(
        map((r) => {
          this._residentialProperties = r.content;
          this._properties = r.content;
          return of({});
        })
      );

  $refreshInvestmentProperties = () =>
    this.propertyService
      .queryInvestmentProperties(this._scenarioId, Constants.PAGE_ALL)
      .pipe(
        map((r) => {
          this._investmentProperties = r.content;
          this._properties = this._properties.concat(r.content);
          return of({});
        })
      );

  $refreshProperties = () =>
    this.propertyService
      .queryResidentialProperties(this._scenarioId, Constants.PAGE_ALL)
      .pipe(
        map((r) => {
          this._residentialProperties = r.content;
          this._properties = r.content;
          return of({});
        })
      )
      .pipe(
        flatMap(() =>
          this.propertyService.queryInvestmentProperties(
            this._scenarioId,
            Constants.PAGE_ALL
          )
        )
      )
      .pipe(
        map((r) => {
          this._investmentProperties = r.content;
          this._properties = this._properties.concat(r.content);
          return of({});
        })
      );

  $refreshPropertiesAndMortgages = () =>
    this.propertyService
      .queryResidentialProperties(this._scenarioId, Constants.PAGE_ALL)
      .pipe(
        map((r) => {
          this._residentialProperties = r.content;
          this._properties = r.content;
          return of({});
        })
      )
      .pipe(
        flatMap(() =>
          this.propertyService.queryInvestmentProperties(
            this._scenarioId,
            Constants.PAGE_ALL
          )
        )
      )
      .pipe(
        map((r) => {
          this._investmentProperties = r.content;
          this._properties = this._properties.concat(r.content);
          return of({});
        })
      )
      .pipe(
        flatMap(() =>
          this.mortgageLiabilityService.query(
            this._scenarioId,
            Constants.PAGE_ALL
          )
        )
      )
      .pipe(map((r) => (this._mortgages = r.content)));

  $refreshPrimaryEarnedIncomes = () =>
    this.earnedIncomeService
      .queryForPerson(this._scenarioId, this._primary.id, Constants.PAGE_ALL)
      .pipe(map((r) => (this._primaryEarnedIncomes = r.content)));

  $refreshPrimaryPensionContributionIncomes = () =>
    this.pensionContributionIncomeService
      .queryForPerson(this._scenarioId, this._primary.id, Constants.PAGE_ALL)
      .pipe(map((r) => (this._primaryPensionIncomes = r.content)));

  $refreshPrimaryPensionContributionExpenses = () =>
    this.pensionExpenseService
      .queryForPerson(this._scenarioId, this._primary.id, Constants.PAGE_ALL)
      .pipe(map((r) => (this._primaryPensionExpenses = r.content)));

  $refreshPartnerEarnedIncomes = () =>
    this.earnedIncomeService
      .queryForPerson(this._scenarioId, this._partner.id, Constants.PAGE_ALL)
      .pipe(map((r) => (this._partnerEarnedIncomes = r.content)));

  $refreshPartnerPensionContributionIncomes = () =>
    this.pensionContributionIncomeService
      .queryForPerson(this._scenarioId, this._partner.id, Constants.PAGE_ALL)
      .pipe(map((r) => (this._partnerPensionIncomes = r.content)));

  $refreshPartnerPensionContributionExpenses = () =>
    this.pensionExpenseService
      .queryForPerson(this._scenarioId, this._partner.id, Constants.PAGE_ALL)
      .pipe(map((r) => (this._partnerPensionExpenses = r.content)));

  $refreshFinancialClassesAndWrappers = () =>
    this.financialService
      .queryFinancialClasses(Constants.PAGE_ALL)
      .pipe(map((r) => (this._financialClasses = r.content)))
      .pipe(
        flatMap(() =>
          this.financialService
            .queryFinancialWrappers(Constants.PAGE_ALL)
            .pipe(map((r) => (this._financialWrappers = r.content)))
        )
      );

  $refreshPrimaryFinancialAssets = () =>
    this.financialService
      .queryFinancialPortfolios(
        this._scenarioId,
        this._primary.id,
        Constants.PAGE_ALL
      )
      .pipe(
        map((r) => {
          this._primaryFinancialPortfolios = r.content.map((p) => {
            return {
              portfolio: p,
              privateContribution: 0,
              employerContribution: 0,
            };
          });

          this._primaryFinancialPortfolios.forEach((p) => {
            const pensionExpense = this._primaryPensionExpenses.filter(
              (e) => e.portfolioId === p.portfolio.id
            )[0];
            const pensionIncome = this._primaryPensionIncomes.filter(
              (e) => e.portfolioId === p.portfolio.id
            )[0];

            p.privateContribution = pensionExpense
              ? pensionExpense.earnedIncomePercentSaved
              : 0;
            p.employerContribution = pensionIncome
              ? pensionIncome.earnedIncomePercentSaved
              : 0;
          });
        })
      )
      .pipe(flatMap(() => this.$refreshIsaEnabled()));

  $refreshPartnerFinancialAssets = () =>
    this.financialService
      .queryFinancialPortfolios(
        this._scenarioId,
        this._partner.id,
        Constants.PAGE_ALL
      )
      .pipe(
        map((r) => {
          this._partnerFinancialPortfolios = r.content.map((p) => {
            return {
              portfolio: p,
              privateContribution: 0,
              employerContribution: 0,
            };
          });
          this._partnerFinancialPortfolios.forEach((p) => {
            const pensionExpense = this._partnerPensionExpenses.filter(
              (e) => e.portfolioId === p.portfolio.id
            )[0];
            const pensionIncome = this._partnerPensionIncomes.filter(
              (e) => e.portfolioId === p.portfolio.id
            )[0];

            p.privateContribution = pensionExpense
              ? pensionExpense.earnedIncomePercentSaved
              : 0;
            p.employerContribution = pensionIncome
              ? pensionIncome.earnedIncomePercentSaved
              : 0;
          });
        })
      )
      .pipe(flatMap(() => this.$refreshIsaEnabled()));

  $refreshIsaEnabled = () => {
    const isa = this._savingsSequences.find((s) => s.wrapper === 'isa');
    if (!isa) {
      return of({});
    }
    if (
      isa.active === true &&
      !this.primaryHasWrapper('isa') &&
      !this.partnerHasWrapper('isa')
    ) {
      isa.active = false;
      return this.preferenceService.update(
        this._scenarioId,
        Constants.SAVINGS_SEQUENCE_PREFERENCE_NAME,
        this._savingsSequences
      );
    }
    if (
      isa.active === false &&
      (this.primaryHasWrapper('isa') || this.partnerHasWrapper('isa'))
    ) {
      isa.active = true;
      return this.preferenceService.update(
        this._scenarioId,
        Constants.SAVINGS_SEQUENCE_PREFERENCE_NAME,
        this._savingsSequences
      );
    }
    return of({});
  };

  calculateMeanReturn(
    assetAllocationPresetsMatrix: number[],
    assetReturnsMatrix: number[][]
  ): number {
    let meanReturn = 0;
    assetAllocationPresetsMatrix.forEach((a, index) => {
      meanReturn += a * assetReturnsMatrix[index][0];
    });
    return Math.round(meanReturn * 100 * 10) / 10;
  }

  refreshBalance() {
    this._loading = true;
    this._scenarioId = this.router.routerState.snapshot.url.split('/')[2];
    this.route.params
      .pipe(
        flatMap((p) => {
          return of({});
        }),
        flatMap(() =>
          this.personsService
            .getPrimary(this._scenarioId)
            .pipe(map((p) => (this._primary = p)))
        ),
        flatMap(() =>
          this.personsService
            .getPartner(this._scenarioId)
            .pipe(map((p) => (this._partner = p)))
        ),
        flatMap(() =>
          forkJoin([
            this.$refreshPreferences(),
            this.$refreshMortgages(),
            this.$refreshUnsecuredLiabilities(),
            this.$refreshResidentialProperties(),
            this.$refreshInvestmentProperties(),
            this.$refreshPrimaryEarnedIncomes(),
            this.$refreshPrimaryPensionContributionIncomes(),
            this.$refreshPrimaryPensionContributionExpenses(),
            !!this.partner ? this.$refreshPartnerEarnedIncomes() : of({}),
            !!this.partner
              ? this.$refreshPartnerPensionContributionIncomes()
              : of({}),
            !!this.partner
              ? this.$refreshPartnerPensionContributionExpenses()
              : of({}),
            this.$refreshFinancialClassesAndWrappers(),
          ])
        ),
        flatMap(() =>
          forkJoin([
            this.$refreshPrimaryFinancialAssets(),
            !!this.partner ? this.$refreshPartnerFinancialAssets() : of({}),
            this.$refreshIsaEnabled(),
          ])
        )
      )
      .pipe(flatMap(() => this.wizardService.listenToNotification()))
      .subscribe(async (notifications) => {
        if (notifications[this.route.snapshot.url[0].path]) {
          this.wizardService.hideNotificationForPath(
            this.route.snapshot.url[0].path
          );
          await this.getLinkedData();
        }
        this._loading = false;
      }, this._onRefreshDone.error);
  }

  updateWrapperPreset(wrapperId: string, presetName: string) {
    for (let i = 0; i < this._financialWrappers.length; i++) {
      if (this._financialWrappers[i].id === wrapperId) {
        if (!this._idAssetAllocationMatrix) {
          this._idAssetAllocationMatrix = [];
        }
        if (presetName === Constants.DEFAULT_WRAPPER_ALLOCATION_NAME) {
          this._idAssetAllocationMatrix[i] = null;
        } else {
          this._idAssetAllocationMatrix[i] = this._assetAllocationPresets.find(
            (p) => p.name === presetName
          ).percentages;
        }
      }
    }
    this._loading = true;
    this.preferenceService
      .update(
        this._scenarioId,
        Constants.ID_ASSET_ALLOCATION_MATRIX_PREFERENCE_NAME,
        this._idAssetAllocationMatrix
      )
      .subscribe(
        () => {},
        () =>
          this.notifier.notify(
            Constants.ERROR,
            'Failed to update wrapper allocation'
          ),
        () => {
          this.preferenceService
            .get(
              this._scenarioId,
              Constants.ID_ASSET_ALLOCATION_MATRIX_PREFERENCE_NAME
            )
            .subscribe((r) => {
              this._idAssetAllocationMatrix = r;
              this.notifier.notify(
                Constants.SUCCESS,
                'Wrapper allocation updated'
              );
              this._loading = false;
            });
        }
      );
  }

  calculateClosestAllocation(percentages: number[]) {
    const diffs = this._assetAllocationPresets.map((preset) => {
      let diff = 0;
      preset.percentages.forEach(
        (p, idx) => (diff += Math.abs(percentages[idx] - p))
      );
      return diff;
    });
    let min = this._financialClasses.length;
    let minIdx = 0;
    diffs.forEach((diff, idx) => {
      if (diff < min) {
        min = diff;
        minIdx = idx;
      }
    });
    return this._assetAllocationPresets[minIdx].name;
  }

  createFinancialAsset(person: Person, wrapper: string, label: string) {
    this._loading = true;

    const portfolio: FinancialPortfolio = {
      name: label,
      value: Constants.DEFAULT_FINANCIAL_ASSET_VALUE,
      currency: Constants.LOCALE_CONFIG[this._locale].currency,
      wrapper,
      description: label,
      profile: this._assetAllocationPresets[0].name,
      fees: Constants.DEFAULT_FINANCIAL_PORTFOLIO_CASH_FEES,
    };
    this.analyticsService.trackAnalyticsEvent(
      AnalyticsEvent.CREATE_ASSET,
      AnalyticsLabel.FINANCIAL
    );

    this.financialService
      .createFinancialPortfolio(this._scenarioId, person.id, portfolio)
      .pipe(finalize(() => (this._loading = false)))
      .subscribe(
        () => {
          person.id === this._primary.id
            ? this.refreshPrimaryFinancialAssets()
            : this.refreshPartnerFinancialAssets();
          this.notifier.notify(Constants.SUCCESS, 'Financial asset created');
        },
        (err) => this.notifier.notify(Constants.ERROR, err)
      );
  }

  createResidentialPropertyAsset() {
    this._loading = true;
    const property: ResidentialProperty = {
      country: Constants.DEFAULT_COUNTRY,
      currency: Constants.LOCALE_CONFIG[this._locale].currency,
      name: Constants.DEFAULT_RESIDENTIAL_PROPERTY_NAME,
      valuationDate: DateUtils.thisYear(),
      value: Constants.DEFAULT_RESIDENTIAL_PROPERTY_VALUE,
      primary: this.residentialPropertyAssets.find((p) => p.primary)
        ? false
        : true,
    };
    this.analyticsService.trackAnalyticsEvent(
      AnalyticsEvent.CREATE_ASSET,
      AnalyticsLabel.RESIDENTIAL_PROPERTY
    );
    this.propertyService
      .createResidentialProperty(this._scenarioId, property)
      .pipe(finalize(() => (this._loading = false)))
      .subscribe(
        () => this.refreshProperties(),
        (err) => this.notifier.notify(Constants.ERROR, err),
        () =>
          this.notifier.notify(
            Constants.SUCCESS,
            'Residential property created'
          )
      );
  }

  createInvestmentPropertyAsset() {
    this._loading = true;
    this.analyticsService.trackAnalyticsEvent(
      AnalyticsEvent.CREATE_ASSET,
      AnalyticsLabel.INVESTMENT_PROPERTY
    );
    const property: InvestmentProperty = {
      name: `${Constants.DEFAULT_INVESTMENT_PROPERTY_NAME} ${
        this._investmentProperties.length + 1
      }`,
      country: Constants.DEFAULT_COUNTRY,
      currency: Constants.LOCALE_CONFIG[this._locale].currency,
      valuationDate: new Date().toISOString(),
      value: Constants.DEFAULT_INVESTMENT_PROPERTY_VALUE,
    };
    this.propertyService
      .createInvestmentProperty(this._scenarioId, property)
      .pipe(finalize(() => (this._loading = false)))
      .subscribe(
        () => this.refreshProperties(),
        (err) => this.notifier.notify(Constants.ERROR, err),
        () =>
          this.notifier.notify(Constants.SUCCESS, 'Investment property created')
      );
  }

  createUnsecuredDebt(debt, name: string) {
    this._loading = true;

    this.analyticsService.trackAnalyticsEvent(
      AnalyticsEvent.CREATE_DEBT,
      AnalyticsLabel.TYPE
    );

    const unsecuredLiability: UnsecuredLiability = {
      name: name || Constants.DEFAULT_UNSECURED_LIABILITY_NAME,
      description: debt.type,
      amount: debt.amount,
      currency: Constants.LOCALE_CONFIG[this._locale].currency,
      startDate: DateUtils.thisYear(),
      endDate: DateUtils.inYears(debt.term),
      annualAverageInterestRate: debt.rate,
      repaymentType: Constants.DEFAULT_UNSECURED_LIABILITY_REPAYMENT_TYPE,
    };
    this.unsecuredLiabilityService
      .create(this._scenarioId, unsecuredLiability)
      .pipe(finalize(() => (this._loading = false)))
      .subscribe(
        () => this.refreshUnsecuredLiabilities(),
        (err) => this.notifier.notify(Constants.ERROR, err),
        () => this.notifier.notify(Constants.SUCCESS, 'Debt created')
      );
  }

  createCreditCardDebt() {
    this.createUnsecuredDebt(
      {
        type: LiabilityTypes.CREDIT_CARD,
        amount: Constants.DEFAULT_UNSECURED_LIABILITY_CREDIT_CARD_AMOUNT,
        rate: Constants.DEFAULT_DEBT_CREDIT_CARD.rate,
        term: Constants.DEFAULT_DEBT_CREDIT_CARD.term,
      },
      'Credit Card'
    );
  }

  createCarLoanDebt() {
    this.createUnsecuredDebt(
      {
        type: LiabilityTypes.CAR_LOAN,
        amount: Constants.DEFAULT_UNSECURED_LIABILITY_CAR_LOAN_AMOUNT,
        rate: Constants.DEFAULT_DEBT_CAR_LOAN.rate,
        term: Constants.DEFAULT_DEBT_CAR_LOAN.term,
      },
      'Car loan'
    );
  }

  createStudentLoanDebt() {
    this.createUnsecuredDebt(
      {
        type: LiabilityTypes.STUDENT_LOAN,
        amount: Constants.DEFAULT_UNSECURED_LIABILITY_STUDENT_LOAN_AMOUNT,
        rate: Constants.DEFAULT_DEBT_STUDENT_LOAN.rate,
        term: Constants.DEFAULT_DEBT_STUDENT_LOAN.term,
      },
      'Student loan'
    );
  }

  createOtherDebt() {
    this.createUnsecuredDebt(
      {
        type: LiabilityTypes.OTHER,
        amount: Constants.DEFAULT_UNSECURED_LIABILITY_OTHER_AMOUNT,
        rate: Constants.DEFAULT_DEBT_OTHER.rate,
        term: Constants.DEFAULT_DEBT_OTHER.term,
      },
      'Other debt'
    );
  }

  createMortgage() {
    this.analyticsService.trackAnalyticsEvent(
      AnalyticsEvent.CREATE_DEBT,
      AnalyticsLabel.TYPE
    );
    if (
      this._residentialProperties.length === 0 &&
      this._investmentProperties.length === 0
    ) {
      return;
    }
    this._loading = true;
    const property =
      this._residentialProperties[0] || this._investmentProperties[0];

    const mortgage: MortgageLiability = {
      name: `${Constants.DEFAULT_MORTGAGE_NAME} ${this._mortgages.length + 1}`,
      amount: Constants.DEFAULT_DEBT_MORTGAGE.LTV * property.value,
      currency: Constants.LOCALE_CONFIG[this._locale].currency,
      balanceAmount: Constants.DEFAULT_DEBT_MORTGAGE.LTV * property.value,
      startDate: DateUtils.thisYear(),
      balanceDate: DateUtils.thisYear(),
      endDate: DateUtils.inYears(Constants.DEFAULT_DEBT_MORTGAGE.term),
      annualAverageInterestRate: Constants.DEFAULT_DEBT_MORTGAGE.rate,
      repaymentType: RepaymentType.PRINCIPAL_AMORTIZATION,
      mortgageType: MortgageType.FIXED_RATE,
      propertyAssetId: property.id,
    };

    this.mortgageLiabilityService.create(this._scenarioId, mortgage).subscribe(
      () => this.refreshMortgages(),
      (err) => {
        this._loading = false;
        this.notifier.notify(Constants.ERROR, err);
      },
      () => this.notifier.notify(Constants.SUCCESS, 'Mortgage created')
    );
  }

  // update
  updateMortgage = (mortgage: MortgageLiability) => {
    if (
      !mortgage.id ||
      mortgage.amount === null ||
      isNaN(mortgage.annualAverageInterestRate) ||
      mortgage.annualAverageInterestRate < 0
    ) {
      return;
    }
    this._loading = true;
    return this.mortgageLiabilityService
      .update(this._scenarioId, mortgage)
      .subscribe(
        () => this.refreshMortgages(),
        (err) => {
          this._loading = false;
          this.notifier.notify(Constants.ERROR, err);
        },
        () => this.notifier.notify(Constants.SUCCESS, 'Mortgage updated')
      );
  };

  updateUnsecuredLiability = (liability: UnsecuredLiability) => {
    if (this.isFromLinkedAccounts(liability)) {
      return;
    }
    if (
      !liability ||
      liability.amount === null ||
      isNaN(liability.annualAverageInterestRate) ||
      liability.annualAverageInterestRate < 0
    ) {
      return;
    }
    this._loading = true;

    this.unsecuredLiabilityService
      .update(this._scenarioId, liability)
      .subscribe(
        () => this.refreshUnsecuredLiabilities(),
        (err) => {
          this._loading = false;
          this.notifier.notify(Constants.ERROR, err);
        },
        () => this.notifier.notify(Constants.SUCCESS, 'Debt updated')
      );
  };

  updateResidentialPropertyAsset = (property: ResidentialProperty) => {
    if (this.isFromLinkedAccounts(property)) {
      return;
    }
    if (!property.id || property.value === null) {
      return;
    }
    this._loading = true;
    this.propertyService
      .updateResidentialProperty(this._scenarioId, property)
      .subscribe(
        () => {
          this.refreshResidentialProperties();
          this.refreshMortgages();
        },
        (err) => {
          this._loading = false;
          this.notifier.notify(Constants.ERROR, err);
        },
        () => {
          this._loading = false;
          this.notifier.notify(
            Constants.SUCCESS,
            'Residential property asset updated'
          );
        }
      );
  };

  updateInvestmentPropertyAsset = (property: InvestmentProperty) => {
    if (this.isFromLinkedAccounts(property)) {
      return;
    }
    if (!property || property.value === null) {
      return;
    }
    this._loading = true;

    this.propertyService
      .updateInvestmentProperty(this._scenarioId, property)
      .subscribe(
        () => this.refreshProperties(),
        (err) => {
          this._loading = false;
          this.notifier.notify(Constants.ERROR, err);
        },
        () => {
          this._loading = false;
          this.notifier.notify(
            Constants.SUCCESS,
            'Investment property updated'
          );
        }
      );
  };

  updatePrimaryPortfolio = (portfolio: FinancialPortfolio) => {
    if (this.isFromLinkedAccounts(portfolio)) {
      return;
    }
    if (
      portfolio.value === null ||
      isNaN(portfolio.fees) ||
      portfolio.fees < 0
    ) {
      return;
    }
    this._loading = true;
    this.financialService
      .updateFinancialPortfolio(this._scenarioId, this._primary.id, portfolio)
      .pipe(concatMap(() => this.$refreshPrimaryFinancialAssets()))
      .pipe(finalize(() => (this._loading = false)))
      .subscribe(
        () => this.notifier.notify(Constants.SUCCESS, 'Portfolio updated'),
        (err) => this.notifier.notify(Constants.ERROR, err)
      );
  };

  updatePartnerPortfolio = (portfolio: FinancialPortfolio) => {
    if (
      portfolio.value === null ||
      isNaN(portfolio.fees) ||
      portfolio.fees < 0
    ) {
      return;
    }
    this._loading = true;
    this.financialService
      .updateFinancialPortfolio(this._scenarioId, this._partner.id, portfolio)
      .pipe(concatMap(() => this.$refreshPartnerFinancialAssets()))
      .pipe(finalize(() => (this._loading = false)))
      .subscribe(
        () => this.notifier.notify(Constants.SUCCESS, 'Portfolio updated'),
        (err) => this.notifier.notify(Constants.ERROR, err)
      );
  };

  getPrimaryPrivateContribution = (portfolio: FinancialPortfolio) =>
    this._primaryPensionExpenses
      .filter((e) => e.portfolioId === portfolio.id)
      .map((e) => e.earnedIncomePercentSaved);

  getPrimaryEmployerContribution = (portfolio: FinancialPortfolio) =>
    this._primaryPensionIncomes
      .filter((e) => e.portfolioId === portfolio.id)
      .map((e) => e.earnedIncomePercentSaved);

  getPartnerPrivateContribution = (portfolio: FinancialPortfolio) =>
    this._partnerPensionExpenses
      .filter((e) => e.portfolioId === portfolio.id)
      .map((e) => e.earnedIncomePercentSaved);

  getPartnerEmployerContribution = (portfolio: FinancialPortfolio) =>
    this._partnerPensionIncomes
      .filter((e) => e.portfolioId === portfolio.id)
      .map((e) => e.earnedIncomePercentSaved);

  updatePrimaryPrivateContribution = (
    p: FinancialPortfolioWithContributions
  ) => {
    this._loading = true;
    iif(
      () =>
        this._primaryPensionExpenses.filter(
          (e) => e.portfolioId === p.portfolio.id
        ).length > 0,
      from(
        this._primaryPensionExpenses.filter(
          (e) => e.portfolioId === p.portfolio.id
        )
      ).pipe(
        concatMap((e) =>
          this.pensionExpenseService.deleteForPerson(
            this._scenarioId,
            this._primary.id,
            e.id
          )
        )
      ),
      of({})
    )
      .pipe(
        flatMap(() =>
          iif(
            () => p.privateContribution > 0 && p.privateContribution <= 1,
            from(this._primaryEarnedIncomes).pipe(
              flatMap((i) =>
                this.pensionExpenseService.createForPerson(
                  this._scenarioId,
                  this._primary.id,
                  new PensionContributionIncomeBuilder()
                    .currency(Constants.LOCALE_CONFIG[this._locale].currency)
                    .earnedIncomeId(i.id)
                    .earnedIncomePercentSaved(p.privateContribution)
                    .maximumEmployerContribution(
                      Constants.DEFAULT_PENSION_INCOME_MAX_CONTRIBUTION
                    )
                    .portfolioId(p.portfolio.id)
                    .build()
                )
              )
            ),
            of({})
          )
        )
      )
      .pipe(finalize(() => (this._loading = false)))
      .subscribe(
        () => this.refreshPrimaryPensionContributionExpenses(),
        (err) =>
          this.notifier.notify(
            Constants.ERROR,
            'Could not update pension contributions'
          )
      );
  };

  updatePrimaryPortfolioFees = (p: FinancialPortfolioWithContributions) => {
    if (isNaN(p.portfolio.fees) || p.portfolio.fees < 0) {
      return;
    }
    this._loading = true;
    iif(
      () => p.portfolio.fees > 0,
      this.financialService
        .updateFinancialPortfolio(
          this._scenarioId,
          this._primary.id,
          p.portfolio
        )
        .pipe(
          catchError((err) => {
            this._loading = false;
            this.notifier.notify(
              Constants.ERROR,
              'Failed to update portfolio fees'
            );
            return of(null);
          })
        ),
      of({})
    )
      .pipe(finalize(() => (this._loading = false)))
      .subscribe();
  };

  updatePrimaryContribution(payload: {
    entity: FinancialPortfolioWithContributions;
    isEmployer: boolean;
  }) {
    payload.isEmployer
      ? this.updatePrimaryEmployerContribution(payload.entity)
      : this.updatePrimaryPrivateContribution(payload.entity);
  }

  updatePartnerContribution(payload: {
    entity: FinancialPortfolioWithContributions;
    isEmployer: boolean;
  }) {
    payload.isEmployer
      ? this.updatePartnerEmployerContribution(payload.entity)
      : this.updatePartnerPrivateContribution(payload.entity);
  }

  updatePrimaryEmployerContribution = (
    p: FinancialPortfolioWithContributions
  ) => {
    this._loading = true;
    iif(
      () =>
        this._primaryPensionIncomes.filter(
          (e) => e.portfolioId === p.portfolio.id
        ).length > 0,
      from(
        this._primaryPensionIncomes.filter(
          (e) => e.portfolioId === p.portfolio.id
        )
      ).pipe(
        concatMap((e) =>
          this.pensionContributionIncomeService.deleteForPerson(
            this._scenarioId,
            this._primary.id,
            e.id
          )
        )
      ),
      of({})
    )
      .pipe(
        flatMap(() =>
          iif(
            () => p.employerContribution > 0 && p.employerContribution <= 1,
            from(this._primaryEarnedIncomes).pipe(
              flatMap((i) =>
                this.pensionContributionIncomeService.createForPerson(
                  this._scenarioId,
                  this._primary.id,
                  new PensionContributionIncomeBuilder()
                    .currency(Constants.LOCALE_CONFIG[this._locale].currency)
                    .earnedIncomeId(i.id)
                    .earnedIncomePercentSaved(p.employerContribution)
                    .maximumEmployerContribution(
                      Constants.DEFAULT_PENSION_INCOME_MAX_CONTRIBUTION
                    )
                    .portfolioId(p.portfolio.id)
                    .build()
                )
              )
            ),
            of({})
          )
        )
      )
      .pipe(finalize(() => (this._loading = false)))
      .subscribe(
        () => this.refreshPrimaryPensionContributionIncomes(),
        (err) =>
          this.notifier.notify(
            Constants.ERROR,
            'Could not update pension contributions'
          )
      );
  };

  updatePartnerPrivateContribution = (
    p: FinancialPortfolioWithContributions
  ) => {
    this._loading = true;
    iif(
      () =>
        this._partnerPensionExpenses.filter(
          (e) => e.portfolioId === p.portfolio.id
        ).length > 0,
      from(
        this._partnerPensionExpenses.filter(
          (e) => e.portfolioId === p.portfolio.id
        )
      ).pipe(
        concatMap((e) =>
          this.pensionExpenseService.deleteForPerson(
            this._scenarioId,
            this._partner.id,
            e.id
          )
        )
      ),
      of({})
    )
      .pipe(
        flatMap(() =>
          iif(
            () => p.privateContribution > 0 && p.privateContribution <= 1,
            from(this._partnerEarnedIncomes).pipe(
              flatMap((i) =>
                this.pensionExpenseService.createForPerson(
                  this._scenarioId,
                  this._partner.id,
                  new PensionContributionIncomeBuilder()
                    .currency(Constants.LOCALE_CONFIG[this._locale].currency)
                    .earnedIncomeId(i.id)
                    .earnedIncomePercentSaved(p.privateContribution)
                    .maximumEmployerContribution(
                      Constants.DEFAULT_PENSION_INCOME_MAX_CONTRIBUTION
                    )
                    .portfolioId(p.portfolio.id)
                    .build()
                )
              )
            ),
            of({})
          )
        )
      )
      .pipe(finalize(() => (this._loading = false)))
      .subscribe(
        () => this.refreshPartnerPensionContributionExpenses(),
        (err) =>
          this.notifier.notify(
            Constants.ERROR,
            'Could not update pension contributions'
          )
      );
  };

  updatePartnerEmployerContribution = (
    p: FinancialPortfolioWithContributions
  ) => {
    this._loading = true;
    iif(
      () =>
        this._partnerPensionIncomes.filter(
          (e) => e.portfolioId === p.portfolio.id
        ).length > 0,
      from(
        this._partnerPensionIncomes.filter(
          (e) => e.portfolioId === p.portfolio.id
        )
      ).pipe(
        concatMap((e) =>
          this.pensionContributionIncomeService.deleteForPerson(
            this._scenarioId,
            this._partner.id,
            e.id
          )
        )
      ),
      of({})
    )
      .pipe(
        flatMap(() =>
          iif(
            () => p.employerContribution > 0 && p.employerContribution <= 1,
            from(this._partnerEarnedIncomes).pipe(
              flatMap((i) =>
                this.pensionContributionIncomeService.createForPerson(
                  this._scenarioId,
                  this._partner.id,
                  new PensionContributionIncomeBuilder()
                    .currency(Constants.LOCALE_CONFIG[this._locale].currency)
                    .earnedIncomeId(i.id)
                    .earnedIncomePercentSaved(p.employerContribution)
                    .maximumEmployerContribution(
                      Constants.DEFAULT_PENSION_INCOME_MAX_CONTRIBUTION
                    )
                    .portfolioId(p.portfolio.id)
                    .build()
                )
              )
            ),
            of({})
          )
        )
      )
      .pipe(finalize(() => (this._loading = false)))
      .subscribe(
        () => this.refreshPartnerPensionContributionIncomes(),
        (err) =>
          this.notifier.notify(
            Constants.ERROR,
            'Could not update pension contributions'
          )
      );
  };

  updatePartnerPortfolioFees = (p: FinancialPortfolioWithContributions) => {
    if (isNaN(p.portfolio.fees) || p.portfolio.fees < 0) {
      return;
    }
    this._loading = true;
    iif(
      () => p.portfolio.fees > 0,
      this.financialService
        .updateFinancialPortfolio(
          this._scenarioId,
          this._partner.id,
          p.portfolio
        )
        .pipe(
          catchError((err) => {
            this._loading = false;
            this.notifier.notify(
              Constants.ERROR,
              'Failed to update portfolio fees'
            );
            return of(null);
          })
        ),
      of({})
    )
      .pipe(finalize(() => (this._loading = false)))
      .subscribe();
  };

  deleteResidentialPropertyAsset = (entity: ResidentialProperty) => {
    this._loading = true;

    this.propertyService
      .deleteResidentialProperty(this._scenarioId, entity.id)
      .subscribe(
        () => this.refreshPropertiesAndMortgages(),
        (err) => {
          this._loading = false;
          this.notifier.notify(Constants.ERROR, err);
        },
        () =>
          this.notifier.notify(
            Constants.SUCCESS,
            'Residential property deleted'
          )
      );
  };

  deleteInvestmentPropertyAsset = (entity: InvestmentProperty) => {
    this._loading = true;
    this.propertyService
      .deleteInvestmentProperty(this._scenarioId, entity.id)
      .subscribe(
        () => this.refreshPropertiesAndMortgages(),
        (err) => {
          this._loading = false;
          this.notifier.notify(Constants.ERROR, err);
        },
        () =>
          this.notifier.notify(Constants.SUCCESS, 'Investment property deleted')
      );
  };

  deletePrimaryFinancialPortfolio = (portfolio: FinancialPortfolio) => {
    this._loading = true;
    this.financialService
      .deleteFinancialPortfolio(
        this._scenarioId,
        this._primary.id,
        portfolio.id
      )
      .pipe(flatMap(() => this.$refreshPrimaryFinancialAssets()))
      .pipe(finalize(() => (this._loading = false)))
      .subscribe(
        () =>
          this.notifier.notify(
            Constants.SUCCESS,
            'Financial portfolio deleted'
          ),
        (err) => this.notifier.notify(Constants.ERROR, err)
      );
  };

  deletePartnerFinancialPortfolio = (portfolio: FinancialPortfolio) => {
    this._loading = true;
    this.financialService
      .deleteFinancialPortfolio(
        this._scenarioId,
        this._partner.id,
        portfolio.id
      )
      .pipe(flatMap(() => this.$refreshPartnerFinancialAssets()))
      .pipe(finalize(() => (this._loading = false)))
      .subscribe(
        () =>
          this.notifier.notify(
            Constants.SUCCESS,
            'Financial portfolio deleted'
          ),
        (err) => this.notifier.notify(Constants.ERROR, err)
      );
  };

  deleteUnsecuredLiability = (entity: UnsecuredLiability) => {
    this._loading = true;

    this.unsecuredLiabilityService
      .delete(this._scenarioId, entity.id)
      .subscribe(
        () => this.refreshUnsecuredLiabilities(),
        (err) => {
          this._loading = false;
          this.notifier.notify(Constants.ERROR, err);
        },
        () => this.notifier.notify(Constants.SUCCESS, 'Debt deleted')
      );
  };

  deleteMortgage = (entity: MortgageLiability) => {
    this._loading = true;

    this.mortgageLiabilityService.delete(this._scenarioId, entity.id).subscribe(
      () => this.refreshMortgages(),
      (err) => {
        this._loading = false;
        this.notifier.notify(Constants.ERROR, err);
      },
      () => this.notifier.notify(Constants.SUCCESS, 'Mortgage deleted')
    );
  };

  getWrapperLabel(id: string) {
    return Constants.FINANCIAL_WRAPPER_NAMES.find((w) => w.id === id).label;
  }

  formattedPresetLabel(label: string, meanReturn: number) {
    return `${label} risk (${meanReturn ?? ''}% expected return)`;
  }

  get featureMoneyHubAccounts() {
    return AppFeatureType.MoneyHubAccounts;
  }

  public primaryHasWrapper = (wrapper: string) =>
    !!this._primaryFinancialPortfolios.find(
      (p) => p.portfolio.wrapper === wrapper
    );

  public partnerHasWrapper = (wrapper: string) =>
    !!this._partnerFinancialPortfolios.find(
      (p) => p.portfolio.wrapper === wrapper
    );

  get primary(): Person {
    return this._primary;
  }

  get partner(): Person {
    return this._partner;
  }

  get yearList() {
    return Array.from(Array(3000).keys()).filter((y) => y >= moment().year());
  }

  get navigation() {
    return this._navigation;
  }

  // getters
  get totalOwned() {
    const accFn = (a, b) => a + b;
    return Math.round(
      this._residentialProperties.map((r) => r.value).reduce(accFn, 0) +
        this._investmentProperties.map((i) => i.value).reduce(accFn, 0) +
        this._primaryFinancialPortfolios
          .map((a) => a.portfolio.value)
          .reduce(accFn, 0) +
        this._partnerFinancialPortfolios
          .map((a) => a.portfolio.value)
          .reduce(accFn, 0)
    );
  }

  get totalOwed() {
    const accFn = (a, b) => a + b;
    return Math.round(
      this._mortgages.map((m) => m.amount).reduce(accFn, 0) +
        this._unsecuredLiabilities.map((u) => u.amount).reduce(accFn, 0)
    );
  }

  get assetDrag() {
    return !this._scrollableAssets;
  }

  get liabilityDrag() {
    return !this._scrollableLiabilities;
  }

  get primaryEarnedIncomes(): EarnedIncome[] {
    return this._primaryEarnedIncomes;
  }

  get partnerEarnedIncomes(): EarnedIncome[] {
    return this._partnerEarnedIncomes;
  }

  get properties() {
    return this._properties;
  }

  get residentialPropertyAssets() {
    return [
      ...this._residentialProperties,
      ...this._linkedResidentialProperties,
    ];
  }

  get investmentPropertyAssets() {
    return [...this._investmentProperties, ...this._linkedInvestmentProperties];
  }

  get primaryFinancialPortfolios() {
    return [
      ...this._primaryFinancialPortfolios,
      ...this._linkedFinancialPortfolios,
    ];
  }

  get partnerFinancialPortfolios() {
    return this._partnerFinancialPortfolios;
  }

  get mortgageDebts() {
    return [...this._mortgages, ...this._linkedMortgages];
  }

  get creditCardDebts() {
    return [
      ...this._unsecuredLiabilities.filter(
        (d) =>
          d.description === LiabilityTypes.CREDIT_CARD ||
          d.description.includes(Constants.SOURCE_MONEYHUB + ':debt:card')
      ),
      ...this._linkedCreditCardLiabilities,
    ];
  }

  get carLoanDebts() {
    return this._unsecuredLiabilities.filter(
      (d) => d.description === LiabilityTypes.CAR_LOAN
    );
  }

  get studentLoanDebts() {
    return this._unsecuredLiabilities.filter(
      (d) => d.description === LiabilityTypes.STUDENT_LOAN
    );
  }

  get otherDebts() {
    return this._unsecuredLiabilities
      .filter((d) => d.description !== LiabilityTypes.CREDIT_CARD)
      .filter((d) => d.description !== LiabilityTypes.CAR_LOAN)
      .filter((d) => d.description !== LiabilityTypes.STUDENT_LOAN)
      .filter(
        (d) => !d.description.includes(Constants.SOURCE_MONEYHUB + ':debt:card')
      );
  }

  get financialWrappers() {
    return this._financialWrappers;
  }

  get allocationPresets() {
    return this._assetAllocationPresets.sort(
      (a, b) => this.riskOrderMap[a.name] - this.riskOrderMap[b.name]
    );
  }

  get currency() {
    return Constants.LOCALE_CONFIG[this._locale].currencySymbol;
  }

  get loading() {
    return this._loading;
  }

  get wrapperAllocationPresets() {
    return this._wrapperAllocationPresets;
  }

  get isMoneyHubEnabled() {
    return this.featureService.hasFeature(AppFeatureType.MoneyHubAccounts);
  }

  getPreference = (
    preferences: any[],
    key: string,
    defaultValue?: any
  ): any => {
    const simpleKeyValue: boolean =
      preferences.filter(
        (p) => Object.keys(p).filter((k) => k === 'key').length > 0
      ).length !== preferences.length;
    if (simpleKeyValue) {
      const keyValuePair = preferences.find((p) => Object.keys(p)[0] === key);
      if (keyValuePair) {
        return keyValuePair[key];
      }
    } else {
      const preference = preferences.find((p) => p.key === key);

      if (preference) {
        return preference.value;
      }
    }

    return defaultValue;
  };

  // rm
  isFromLinkedAccounts(data) {
    // return (data.source === Constants.SOURCE_MONEYHUB || data.description === Constants.SOURCE_MONEYHUB);
    return data.source === Constants.SOURCE_MONEYHUB;
  }

  removeLinkedUnsecuredDebt(debt) {
    if (debt.id) {
      this._unsecuredLiabilities = this._unsecuredLiabilities.map((e) => {
        if (
          !!this._propDebtCardTmp[debt.description] &&
          e.id === this._propDebtCardTmp[debt.description].id
        ) {
          e = this._propDebtCardTmp[debt.description];
          e.source = null;
        }
        return e;
        // (e.id === this._propResidentialTmp.id) ? this._propResidentialTmp : e
      });
    } else {
      this._linkedCreditCardLiabilities =
        this._linkedCreditCardLiabilities.filter((d) => d.name !== debt.name);
    }
  }

  removeLinkedDebt(debt) {}

  saveLinkedUnsecuredDebt(debt) {
    this._loading = true;
    iif(
      () => !!debt.id,
      this.unsecuredLiabilityService.update(this._scenarioId, debt),
      this.unsecuredLiabilityService.create(this._scenarioId, debt)
    ).subscribe(
      () => {
        this.refreshUnsecuredLiabilities();
        this.removeLinkedUnsecuredDebt(debt);
      },
      (err) => {
        this._loading = false;
        this.notifier.notify(Constants.ERROR, err);
      },
      () =>
        this.notifier.notify(
          Constants.SUCCESS,
          `Debt ${debt.id ? 'updated' : 'created'}`
        )
    );
  }

  removeLinkedPortfolio(p) {
    if (p.portfolio.id) {
      this._primaryFinancialPortfolios = this._primaryFinancialPortfolios.map(
        (e) => {
          if (
            !!this._propCashTmp[p.portfolio.description] &&
            e.portfolio.id === this._propCashTmp[p.portfolio.description].id
          ) {
            e.portfolio = this._propCashTmp[p.portfolio.description];
            e.portfolio.source = null;
          }
          return e;
        }
      );
    } else {
      this._linkedFinancialPortfolios = this._linkedFinancialPortfolios.filter(
        (a) => p.portfolio.name !== a.portfolio.name
      );
    }
  }

  removeLinkedResidentialProperty(property) {
    if (property.id) {
      this._residentialProperties = this._residentialProperties.map((e) => {
        if (
          !!this._propResidentialTmp[property.description] &&
          e.id === this._propResidentialTmp[property.description].id
        ) {
          e = this._propResidentialTmp[property.description];
          e.source = null;
        }
        return e;
        // (e.id === this._propResidentialTmp.id) ? this._propResidentialTmp : e
      });
    } else {
      this._linkedResidentialProperties =
        this._linkedResidentialProperties.filter(
          (p) => p.name !== property.name
        );
    }
  }

  removeLinkedInvestmentProperty(property) {
    if (property.id) {
      this._investmentProperties = this._investmentProperties.map((e) => {
        if (
          !!this._propInvestmentTmp[property.description] &&
          e.id === this._propInvestmentTmp[property.description].id
        ) {
          e = this._propInvestmentTmp[property.description];
          e.source = null;
        }
        return e;
        // (e.id === this._propInvestmentTmp.id) ? this._propInvestmentTmp : e
      });
    } else {
      this._linkedInvestmentProperties =
        this._linkedInvestmentProperties.filter(
          (p) => p.name !== property.name
        );
    }
  }

  removeLinkedMortgage(mortgage) {
    if (!mortgage) {
      return;
    }
    if (mortgage.id) {
      this._mortgages = this._mortgages.map((e) => {
        if (
          !!this._propResidentialTmp[mortgage.description] &&
          e.id === this._propResidentialTmp[mortgage.description].id
        ) {
          e = this._propResidentialTmp[mortgage.description];
          e.source = null;
        }
        if (
          !!this._propInvestmentTmp[mortgage.description] &&
          e.id === this._propInvestmentTmp[mortgage.description].id
        ) {
          e = this._propInvestmentTmp[mortgage.description];
          e.source = null;
        }
        return e;
        // (e.id === this._propResidentialTmp.id) ? this._propResidentialTmp : e
      });
    } else {
      this._linkedMortgages = this._linkedMortgages.filter(
        (p) => p.name !== mortgage.name
      );
    }
  }

  saveLinkedPortfolio(p) {
    this._loading = true;
    iif(
      () => !!p.portfolio.id,
      this.financialService.updateFinancialPortfolio(
        this._scenarioId,
        this.primary.id,
        p.portfolio
      ),
      this.financialService.createFinancialPortfolio(
        this._scenarioId,
        this.primary.id,
        p.portfolio
      )
    ).subscribe(
      () => {
        this.refreshPrimaryFinancialAssets();
        this.removeLinkedPortfolio(p);
      },
      (err) => {
        this._loading = false;
        this.notifier.notify(Constants.ERROR, err);
      },
      () =>
        this.notifier.notify(
          Constants.SUCCESS,
          `portfolio ${p.portfolio.id ? 'updated' : 'created'}`
        )
    );
  }

  saveLinkedResidentialProperty(p) {
    this._loading = true;
    let mortgage = null;
    iif(
      () => !!p.id,
      this.propertyService.updateResidentialProperty(this._scenarioId, p),
      this.propertyService.createResidentialProperty(this._scenarioId, p).pipe(
        flatMap((res) => {
          mortgage = this._linkedMortgages.find(
            (m) => m.description === p.name
          );
          if (mortgage) {
            mortgage.propertyAssetId = res.id;
            return this.mortgageLiabilityService.create(
              this._scenarioId,
              mortgage
            );
          } else {
            return of({});
          }
        })
      )
    ).subscribe(
      () => {
        this.refreshResidentialProperties();
        this.refreshMortgages();
        this.removeLinkedResidentialProperty(p);
        if (mortgage) {
          this.removeLinkedMortgage(mortgage);
        }
      },
      (err) => {
        this._loading = false;
        this.notifier.notify(Constants.ERROR, err);
      },
      () =>
        this.notifier.notify(
          Constants.SUCCESS,
          `Property ${p.id ? 'updated' : 'created'}`
        )
    );
  }

  saveLinkedInvestmentProperty(p) {
    this._loading = true;
    let mortgage = null;
    iif(
      () => !!p.id,
      this.propertyService.updateInvestmentProperty(this._scenarioId, p),
      this.propertyService.createInvestmentProperty(this._scenarioId, p).pipe(
        flatMap((res) => {
          mortgage = this._linkedMortgages.find(
            (m) => m.description === p.name
          );
          if (mortgage) {
            mortgage.propertyAssetId = res.id;
            return this.mortgageLiabilityService.create(
              this._scenarioId,
              mortgage
            );
          } else {
            return of({});
          }
        })
      )
    ).subscribe(
      () => {
        this.refreshInvestmentProperties();
        this.refreshMortgages();
        this.removeLinkedInvestmentProperty(p);
        if (mortgage) {
          this.removeLinkedMortgage(mortgage);
        }
      },
      (err) => {
        this._loading = false;
        this.notifier.notify(Constants.ERROR, err);
      },
      () =>
        this.notifier.notify(
          Constants.SUCCESS,
          `Property ${p.id ? 'updated' : 'created'}`
        )
    );
  }
}
