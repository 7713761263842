import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Constants } from 'src/app/app.constants';
import { MatDialog } from '@angular/material/dialog';
import { UpgradeDialogComponent } from 'src/app/components/common/upgrade-dialog/upgrade.dialog.component';
import { AppFeatureType } from 'src/app/feature';
import { FeatureService } from 'src/app/feature/feature.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { GuestUtils } from 'src/app/utils';

@Component({
  selector: 'app-account-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  scenarioId: string;

  featureMoneyhub = AppFeatureType.MoneyHubAccounts;

  constructor(
    private dialog: MatDialog,
    private featureService: FeatureService,
    private route: ActivatedRoute,
    private authService: OAuthService
  ) {}

  ngOnInit() {
    this.scenarioId = this.route.snapshot.params['scenarioId'];
  }

  logout() {
    localStorage.clear();
    this.authService.logOut();
  }

  get registered() {
    return GuestUtils.registered();
  }

  showRegistrationPopup = () =>
    this.dialog.open(UpgradeDialogComponent, {
      width: '450px',
      height: '250px',
    });
}
