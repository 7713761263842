import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Constants } from 'src/app/app.constants';
import { ResidentialProperty } from 'src/app/model';

@Component({
  selector: 'app-balance-asset-property',
  templateUrl: './asset-property.component.html',
  styleUrls: ['./asset-property.component.scss'],
})
export class BalanceAssetPropertyComponent {
  @Input() entity: ResidentialProperty;

  @Output() update = new EventEmitter<ResidentialProperty>();
  @Output() remove = new EventEmitter<ResidentialProperty>();
  @Output() updateLinked = new EventEmitter<ResidentialProperty>();
  @Output() removeLinked = new EventEmitter<ResidentialProperty>();

  private _showInputForId: string;

  showNameInput(ref: HTMLInputElement) {
    setTimeout(() => ref.focus(), 50);
  }

  onUpdate() {
    this.update.emit(this.entity);
  }

  onRemove() {
    this.remove.emit(this.entity);
  }

  onUpdateLinked() {
    this.updateLinked.emit(this.entity);
  }

  onRemoveLinked() {
    this.removeLinked.emit(this.entity);
  }

  isFromLinkedAccounts(data) {
    return data.source === Constants.SOURCE_MONEYHUB;
  }

  get showInputForId() {
    return this._showInputForId;
  }

  set showInputForId(id: string) {
    this._showInputForId = id;
  }
}
